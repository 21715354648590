export const IMPORT_COLLECTIONS = 'IMPORT_COLLECTIONS';
import axios from 'axios';
import URL from '../../helpers/helper_url'

export default function importCollections(user_id) {
  console.log("Import collections")
  const url = URL + `/collections`;
  const request = axios.get(url, {params: {user_id: user_id}});

  return {
    type: IMPORT_COLLECTIONS,
    payload: request
  };
};
