import React, { Component } from 'react';

import AudioTag from '../containers/audio_tag';
import PlayerInfo from '../containers/player_info';
import PlayerToggleMobile from '../containers/player_toggle_mobile';
import PlayerToggle from '../containers/player_toggle';
import PileGestionButton from '../containers/pile_gestion_button';
import ProgressBar from '../containers/progress_bar';
import PlayerControls from '../containers/player_controls';

export default class Player extends Component {

  render() {
    return (
      <div className="player fixed-bottom container-fluid">
        <div className="player__interface d-flex flex-column-reverse">
          <PlayerToggleMobile/>
          <div className="row position-relative order-1">
            <PlayerToggle/>
            <div className="col-10 mt-1 mt-sm-2 mb-sm-3 mb-1">
              <PlayerControls/>
              <ProgressBar/>
            </div>
            <PileGestionButton/>
          </div>
          <PlayerInfo/>
          <AudioTag/>
        </div>
      </div>
    );
  }
}
