export default function formatTime(time_in_s) {
  var hrs = Math.trunc(time_in_s/3600)
  var mins = Math.trunc((time_in_s - (hrs * 3600))/60);
  var secs = Math.trunc(time_in_s - (hrs *3600) - (mins * 60));
  if (hrs > 0) {
    var time_str = hrs + 'h ' + mins +'mn';
  } else if (hrs < 1 && mins > 1) {
    var time_str = mins + 'mn ' + secs + 's'
  } else if (mins < 1) {
    var time_str = secs + 's'
  }
  return time_str
}
// 
// export default function formatDate(publication_date) {
//   var months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
//   var date = new Date(publication_date);
//   var month = months[date.getMonth()]
//   var year  = date.getFullYear()
//   return `${month} ${year} `
// }
