import React, { Component } from 'react';

export default class Presse extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <main className="page--static" role="main">
        <div className="headband e-card card--title-middle card--plain card--title-play container-fluid ">
          <div className="row">
            <div className="headband__img card__title col-12 col-md-5 col-lg-4">
              <div className="title__holder">
                <h2 className="title__text">
                  Espace Presse
                </h2>
              </div>
            </div>
            <div className="headband__info col-12 col-md-7 col-lg-8 d-flex flex-column justify-content-between">
              <div className="headband__metas row align-items-baseline"></div>
              <div className="headband__description p-3 p-lg-5 row">
                <div className="col p-0">
                  <p>Vous voulez en savoir plus sur elson ? N’hésitez pas à nous contacter.</p>
                  <p>Vous pouvez télécharger nos logos <span title="Copyright">©</span> elson ci‑dessous, ou encore accéder à nos communiqués et notre revue de presse.</p>
                </div>
              </div>
              <div className="headband__actions actions row"></div>
            </div>
          </div>
        </div>
        <div className="contents container">
          <h3 className="title--l2 title--label">Contact Presse</h3>
          <a href="mailto:tiphaine@elson.fr"><span>tiphaine</span>&#64;<span>elson.fr</span></a>

          <h3 className="title--l2">Revue de Presse <small>-&nbsp;mise à jour Septembre 2021</small></h3>

          <section role="table">
            <div className="sr-only" role="rowgroup">
              <div className="row" role="row" aria-rowindex="1">
                <span role="columnheader">Date</span>
                <span role="columnheader">Author</span>
                <span role="columnheader">Titre</span>
                <span role="columnheader">Actions</span>
              </div>
            </div>

            <div role="rowgroup">

                        <div className="row no-gutters" role="row" aria-rowindex="90">
                <div className="table__date col-6 col-sm-3 col-md-2 order-1" role="gridcell">
                  03/05/2020
                </div>
                <div className="table__author col-12 col-sm-5 col-md-3 order-3 order-sm-2 text_truncate "role="gridcell">
                  France Inter
                </div>
                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Farniente, sexe, évasion : cinq podcasts qui vous aideront à tenir le coup
                  </div>
                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.franceinter.fr/societe/farniente-sexe-evasion-cinq-podcasts-qui-vous-aideront-a-tenir-le-coup">Lire l'article</a>
                  </div>
              </div>

                <div className="row no-gutters" role="row" aria-rowindex="90">
                <div className="table__date col-6 col-sm-3 col-md-2 order-1" role="gridcell">
                  19/04/2020
                </div>
                <div className="table__author col-12 col-sm-5 col-md-3 order-3 order-sm-2 text_truncate "role="gridcell">
                  Maddyness
                </div>
                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Le programme de formation aux podcasts Tac Tac Tac est désormais disponible en ligne
                  </div>
                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.maddyness.com/2020/04/29/en-bref-les-actus-de-marc-simoncini-guyane-tech-et-tac-tac-tac/">Lire l'article</a>
                  </div>
              </div>

               <div className="row no-gutters" role="row" aria-rowindex="90">
                <div className="table__date col-6 col-sm-3 col-md-2 order-1" role="gridcell">
                  18/10/2019
                </div>
                <div className="table__author col-12 col-sm-5 col-md-3 order-3 order-sm-2 text_truncate "role="gridcell">
                  Europe 1
                </div>
                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Les nouveaux acteurs du podcast français
                  </div>
                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.europe1.fr/emissions/la-france-bouge-avec-raphaelle-duchemin/les-nouveaux-acteurs-du-podcast-francais-3922865">Ecoutez l'émission</a>
                  </div>
              </div>

               <div className="row no-gutters" role="row" aria-rowindex="90">
                <div className="table__date col-6 col-sm-3 col-md-2 order-1" role="gridcell">
                  06/07/2019
                </div>
                <div className="table__author col-12 col-sm-5 col-md-3 order-3 order-sm-2 text_truncate "role="gridcell">
                  Telerama
                </div>
                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Podcasts : notre guide pour se repérer dans les studios, applications ou plateformes
                  </div>
                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.telerama.fr/radio/podcasts-notre-guide-pour-se-reperer-dans-les-studios,-applications-ou-plateformes,n6315595.php">Lire l'article</a>
                  </div>
              </div>



              <div className="row no-gutters" role="row" aria-rowindex="90">
                <div className="table__date col-6 col-sm-3 col-md-2 order-1" role="gridcell">
                  11/06/2019
                </div>
                <div className="table__author col-12 col-sm-5 col-md-3 order-3 order-sm-2 text_truncate "role="gridcell">
                  France Culture - Du grain à moudre
                </div>
                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Streaming, podcast... et je remets le son ?
                  </div>
                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.franceculture.fr/emissions/du-grain-a-moudre/du-grain-a-moudre-emission-du-mardi-11-juin-2019">Ecoutez l'émission</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="91">
                <div className="table__date col-6 col-sm-3 col-md-2 order-1" role="gridcell">
                  07/05/2019
                </div>
                <div className="table__author col-12 col-sm-5 col-md-3 order-3 order-sm-2 text_truncate "role="gridcell">
                  Bon pour la tête
                </div>
                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      La radio est morte, vive l'audio!
                  </div>
                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://bonpourlatete.com/actuel/la-radio-est-morte-vive-l-audio">Lire l'article</a>
                  </div>
              </div>
              <div className="row no-gutters" role="row" aria-rowindex="92">
                <div className="table__date col-6 col-sm-3 col-md-2 order-1" role="gridcell">
                  07/03/2019
                </div>

                <div className="table__author col-12 col-sm-5 col-md-3 order-3 order-sm-2 text_truncate "role="gridcell">
                  2Radio FMR - émission CPU
                </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Carine Fillot, fondatrice du service de recommandation de podcasts Elson
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://cpu.dascritch.net/post/2019/03/07/Carine-Fillot%2C-fondatrice-du-service-de-recommandation-de-podcasts-Elson">Ecoutez l'émission</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="93">
                <div className="table__date col-6 col-sm-3 col-md-2 order-1" role="gridcell">
                  28/02/2019
                </div>

                <div className="table__author col-12 col-sm-5 col-md-3 order-3 order-sm-2 text_truncate "role="gridcell">
                  L'ADN
                </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Les conseils de 4 experts pour réussir son podcast
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.ladn.eu/media-mutants/radio-et-son/tendance-podcast-monetisation-formats-sujets-reussir-podcast/">Lire l'article</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="94">
                <div className="table__date col-6 col-sm-3 col-md-2 order-1" role="gridcell">
                  19/02/2019
                </div>

                <div className="table__author col-12 col-sm-5 col-md-3 order-3 order-sm-2 text_truncate "role="gridcell">
                  E-marketing
                </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Comment choisir sa solution de création de podcast ?
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.e-marketing.fr/Thematique/media-1093/Breves/Comment-choisir-solution-creation-podcast-337513.htm">Lire l'article</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="95">
                <div className="table__date col-6 col-sm-3 col-md-2 order-1" role="gridcell">
                  13/02/2019
                </div>

                <div className="table__author col-12 col-sm-5 col-md-3 order-3 order-sm-2 text_truncate "role="gridcell">
                  France O
                </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Le podcast révolutionne la radio ! (à 16 min.)
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="http://ltom.fr/emissions/Le-podcast-revolutionne-la-radio-Mercredi-fevrier-1065/x72gssy">Lire l'article</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="97">
                <div className="table__date col-6 col-sm-3 col-md-2 order-1" role="gridcell">
                  24/01/2019
                </div>

                <div className="table__author col-12 col-sm-5 col-md-3 order-3 order-sm-2 text_truncate "role="gridcell">
                  Satellifax
                </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Les lauréats des « Grands prix 2019 » du salon de la radio et de l'audio digital
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.satellifax.com/fr/tour/news/265835/salon-radio-audio-digital-laureats-grands-prix-2019.html">Lire l'article</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="97">
                <div className="table__date col-6 col-sm-3 col-md-2 order-1" role="gridcell">
                  07/02/2019
                </div>

                <div className="table__author col-12 col-sm-5 col-md-3 order-3 order-sm-2 text_truncate "role="gridcell">
                  La Croix
                </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Le nouvel eldorado podcast
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.la-croix.com/Economie/Medias/Le-nouvel-eldorado-podcast-2019-02-07-1201000961">Lire l'article</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="98">
                <div className="table__date col-6 col-sm-3 col-md-2 order-1" role="gridcell">
                  01/02/2019
                </div>

                <div className="table__author col-12 col-sm-5 col-md-3 order-3 order-sm-2 text_truncate "role="gridcell">
                  Capital
                </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Podcasts, streaming... le son, un business d'avenir !
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.capital.fr/votre-carriere/podcasts-streaming-le-son-un-business-davenir-1325912">Lire l'article</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="99">
                <div className="table__date col-6 col-sm-3 col-md-2 order-1" role="gridcell">
                  27/11/2018
                </div>

                <div className="table__author col-12 col-sm-5 col-md-3 order-3 order-sm-2 text_truncate "role="gridcell">
                  Télérama
                </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Elson, la plateforme qui veut trier les podcasts pour vous
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.20minutes.fr/podcast/2356959-20181019-manque-podcasts-toucher-grand-public-francais">Lire l'article</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="100">
                <div className="table__date col-6 col-sm-3 col-md-2 order-1" role="gridcell">
                  27/11/2018
                </div>

                <div className="table__author col-12 col-sm-5 col-md-3 order-3 order-sm-2 text_truncate "role="gridcell">
                  France Info
                </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Elson, la plateforme qui défriche le terrain du podcast
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.francetvinfo.fr/replay-radio/info-medias/elson-la-plateforme-qui-defriche-le-terrain-du-podcast_3032641.html">Regarder la vidéo</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="101">
                <div className="table__date col-6 col-sm-3 col-md-2 order-1" role="gridcell">
                  19/10/2018
                </div>

                <div className="table__author col-12 col-sm-5 col-md-3 order-3 order-sm-2 text_truncate "role="gridcell">
                  20 minutes
                </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Que manque-t-il aux podcasts pour toucher le grand public ?
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.20minutes.fr/podcast/2356959-20181019-manque-podcasts-toucher-grand-public-francais">Lire l'article</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="102">
                <div className="table__date col-6 col-sm-3 col-md-2 order-1" role="gridcell">
                  19/10/2018
                </div>

                <div className="table__author col-12 col-sm-5 col-md-3 order-3 order-sm-2 text_truncate "role="gridcell">
                  Sciences et Avenir
                </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Elson, une plateforme de sélection de podcasts
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.sciencesetavenir.fr/decouvrir/la-gaite-lyrique-accueille-le-premier-podcast-festival_128761">Regarder la vidéo</a>
                  </div>
              </div>


              <div className="row no-gutters" role="row" aria-rowindex="103">
                <div className="table__date col-6 col-sm-3 col-md-2 order-1" role="gridcell">
                  12/10/2018
                </div>

                <div className="table__author col-12 col-sm-5 col-md-3 order-3 order-sm-2 text_truncate "role="gridcell">
                  Le Monde
                </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Livres audio, podcasts... Les Français ont la tête dans le casque
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.lemonde.fr/acces-restreint/m-perso/article/2018/10/12/8a17267cc360d78a7d0622abcbf8439a_5368587_4497916.html">Lire l'article</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="104">
                  <div className="
                          table__date
                          col-6 col-sm-3 col-md-2
                          order-1
                      "
                      role="gridcell"
                  >
                      27/08/2018
                  </div>

                  <div className="
                          table__author
                          col-12 col-sm-5 col-md-3
                          order-3 order-sm-2
                          text_truncate
                      "
                      role="gridcell"
                  >
                      RTBF
                  </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      "Hack the radio" : des playlists de podcasts selon vos goûts
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.rtbf.be/webcreation/actualites/detail_hack-the-radio-des-playlists-de-podcasts-selon-vos-gouts?id=10003686">Lire l'article</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="105">
                  <div className="
                          table__date
                          col-6 col-sm-3 col-md-2
                          order-1
                      "
                      role="gridcell"
                  >
                      10/07/2018
                  </div>

                  <div className="
                          table__author
                          col-12 col-sm-5 col-md-3
                          order-3 order-sm-2
                          text_truncate
                      "
                      role="gridcell"
                  >
                      Meta Media - France Tv
                  </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Le podcast sortira-til de sa niche ?
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.meta-media.fr/2018/07/10/le-podcast-sortira-t-il-de-la-niche.html">Lire l'article</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="106">
                  <div className="
                          table__date
                          col-6 col-sm-3 col-md-2
                          order-1
                      "
                      role="gridcell"
                  >
                      06/05/2018
                  </div>

                  <div className="
                          table__author
                          col-12 col-sm-5 col-md-3
                          order-3 order-sm-2
                          text_truncate
                      "
                      role="gridcell"
                  >
                      Radio Campus Paris
                  </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      La Radio du Futur
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.radiocampusparis.org/mardi-5-juin-radio-vers-le-futur/">Écouter l’émission</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="107">
                  <div className="
                          table__date
                          col-6 col-sm-3 col-md-2
                          order-1
                      "
                      role="gridcell"
                  >
                      11/05/2018
                  </div>

                  <div className="
                          table__author
                          col-12 col-sm-5 col-md-3
                          order-3 order-sm-2
                          text_truncate
                      "
                      role="gridcell"
                  >
                      Nieman Journalism Lab
                  </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      BuzzFeed News’ podcast cohost, Jojo the bot, wants to help listeners follow along without friction
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="http://www.niemanlab.org/2018/05/buzzfeed-news-podcast-cohost-jojo-the-bot-wants-to-help-listeners-follow-along-without-friction/">Lire l'article</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="108">
                  <div className="
                          table__date
                          col-6 col-sm-3 col-md-2
                          order-1
                      "
                      role="gridcell"
                  >
                      18/04/2018
                  </div>

                  <div className="
                          table__author
                          col-12 col-sm-5 col-md-3
                          order-3 order-sm-2
                          text_truncate
                      "
                      role="gridcell"
                  >
                      Onlike
                  </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Podcasts : pourquoi souffrent-ils d’un déficit de notoriété ?
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.onlike.net/societe/podcasts-notoriete/">Lire l'article</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="109">
                  <div className="
                          table__date
                          col-6 col-sm-3 col-md-2
                          order-1
                      "
                      role="gridcell"
                  >
                      11/04/2018
                  </div>

                  <div className="
                          table__author
                          col-12 col-sm-5 col-md-3
                          order-3 order-sm-2
                          text_truncate
                      "
                      role="gridcell"
                  >
                      L'ADN
                  </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Paris&Co dévoile la première promotion du programme Médiastart
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.ladn.eu/news-business/actualites-startups/parisco-devoile-la-premiere-promotion-du-programme-mediastart/">Lire l'article</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="110">
                  <div className="
                          table__date
                          col-6 col-sm-3 col-md-2
                          order-1
                      "
                      role="gridcell"
                  >
                      11/04/2018
                  </div>

                  <div className="
                          table__author
                          col-12 col-sm-5 col-md-3
                          order-3 order-sm-2
                          text_truncate
                      "
                      role="gridcell"
                  >
                      Planeta Chatbot
                  </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Chatbot, la inteligencia artificial asequible para impulsar el audio
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://planetachatbot.com/chatbot-la-inteligencia-artificial-asequible-para-impulsar-el-audio-21ed810f5a3f">Lire l'article</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="111">
                  <div className="
                          table__date
                          col-6 col-sm-3 col-md-2
                          order-1
                      "
                      role="gridcell"
                  >
                      04/04/2018
                  </div>

                  <div className="
                          table__author
                          col-12 col-sm-5 col-md-3
                          order-3 order-sm-2
                          text_truncate
                      "
                      role="gridcell"
                  >
                      Geekzone
                  </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Dans Le Canap ‘ #17 : Carine Fillot, fondatrice de Hack The Radio
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.geekzone.fr/2018/04/04/dans-le-canap-17-carine-fillot-fondatrice-de-hack-the-radio/">Écouter l’émission</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="112">
                  <div className="
                          table__date
                          col-6 col-sm-3 col-md-2
                          order-1
                      "
                      role="gridcell"
                  >
                      20/03/2018
                  </div>

                  <div className="
                          table__author
                          col-12 col-sm-5 col-md-3
                          order-3 order-sm-2
                          text_truncate
                      "
                      role="gridcell"
                  >
                      Planète Bizness
                  </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Ebdo, Presstalis, précarité : le journalisme a-t-il un avenir économique ?
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.planete-business.com/2018/03/26/ebdo-presstalis-journalisme-audiens-nieman/">Lire l'article</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="113">
                  <div className="
                          table__date
                          col-6 col-sm-3 col-md-2
                          order-1
                      "
                      role="gridcell"
                  >
                      08/03/2018
                  </div>

                  <div className="
                          table__author
                          col-12 col-sm-5 col-md-3
                          order-3 order-sm-2
                          text_truncate
                      "
                      role="gridcell"
                  >
                      Syntone
                  </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Au coeur du bouillonement podcastique
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="http://syntone.fr/au-coeur-du-bouillonnement-podcastique/">Lire l'article</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="114">
                  <div className="
                          table__date
                          col-6 col-sm-3 col-md-2
                          order-1
                      "
                      role="gridcell"
                  >
                      20/02/2018
                  </div>

                  <div className="
                          table__author
                          col-12 col-sm-5 col-md-3
                          order-3 order-sm-2
                          text_truncate
                      "
                      role="gridcell"
                  >
                      Radiotips
                  </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Pourquoi les podcasts indépendants sont (presque) invisibles
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="http://radiotips.fr/2018/02/28/podcasts-independants-presque-invisibles/">Lire l'article</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="115">
                  <div className="
                          table__date
                          col-6 col-sm-3 col-md-2
                          order-1
                      "
                      role="gridcell"
                  >
                      18/02/2018
                  </div>

                  <div className="
                          table__author
                          col-12 col-sm-5 col-md-3
                          order-3 order-sm-2
                          text_truncate
                      "
                      role="gridcell"
                  >
                      L'ADN
                  </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Podcast : « Il manque une expérience à la Netflix pour les faire décoller »
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.ladn.eu/media-mutants/podcast-il-manque-une-experience-a-la-netflix-pour-les-faire-decoller/">Lire l'article</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="116">
                  <div className="
                          table__date
                          col-6 col-sm-3 col-md-2
                          order-1
                      "
                      role="gridcell"
                  >
                      13/01/2018
                  </div>

                  <div className="
                          table__author
                          col-12 col-sm-5 col-md-3
                          order-3 order-sm-2
                          text_truncate
                      "
                      role="gridcell"
                  >
                      RTS - Médialogues
                  </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      La radio nʹa pas dit son dernier mot
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.rts.ch/play/radio/medialogues/audio/la-radio-na-pas-dit-son-dernier-mot?id=9210482&station=a9e7621504c6959e35c3ecbe7f6bed0446cdf8da">Écouter l’émission</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="117">
                  <div className="
                          table__date
                          col-6 col-sm-3 col-md-2
                          order-1
                      "
                      role="gridcell"
                  >
                      05/11/2017
                  </div>

                  <div className="
                          table__author
                          col-12 col-sm-5 col-md-3
                          order-3 order-sm-2
                          text_truncate
                      "
                      role="gridcell"
                  >
                      Le Petit bulletin
                  </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Hack the Radio : l’initiative qui innove pour digitaliser la radio
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="http://www.petit-bulletin.fr/grenoble/blog-442315.html">Lire l'article</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="118">
                  <div className="
                          table__date
                          col-6 col-sm-3 col-md-2
                          order-1
                      "
                      role="gridcell"
                  >
                      02/11/2017
                  </div>

                  <div className="
                          table__author
                          col-12 col-sm-5 col-md-3
                          order-3 order-sm-2
                          text_truncate
                      "
                      role="gridcell"
                  >
                      France Culture - Soft power
                  </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      L'invitée de Zoé Sfez: Carine Fillot
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.franceculture.fr/emissions/soft-power/choisir-les-bonnes-applications-entre-bons-plans-et-arnaques">Écouter l’émission</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="119">
                  <div className="
                          table__date
                          col-6 col-sm-3 col-md-2
                          order-1
                      "
                      role="gridcell"
                  >
                      02/11/2017
                  </div>

                  <div className="
                          table__author
                          col-12 col-sm-5 col-md-3
                          order-3 order-sm-2
                          text_truncate
                      "
                      role="gridcell"
                  >
                      Nieman Journalism Lab
                  </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Hack the Radio, a French startup, aims to liberate podcasts by streaming audio from a chatbot
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="http://www.niemanlab.org/2017/11/hack-the-radio-a-french-startup-aims-to-liberate-podcasts-by-streaming-audio-from-a-chatbot/">Lire l'article</a>
                  </div>
              </div>

              <div className="row no-gutters" role="row" aria-rowindex="120">
                  <div className="
                          table__date
                          col-6 col-sm-3 col-md-2
                          order-1
                      "
                      role="gridcell"
                  >
                      06/02/2017
                  </div>

                  <div className="
                          table__author
                          col-12 col-sm-5 col-md-3
                          order-3 order-sm-2
                          text_truncate
                      "
                      role="gridcell"
                  >
                      Le CFJ, centre de formation des journalistes
                  </div>

                  <div className="
                          table__title
                          col-12 col-md-4 col-lg-5
                          order-4 order-md-3
                          text-truncate
                      "
                      role="gridcell"
                  >
                      Hack the Radio : Carine Fillot remporte le prix Next Journalism
                  </div>

                  <div className="table__action col-6 col-sm-4 col-md-3 col-lg-2 order-2 order-sm-3 order-md-4 pl-3 text-right text-lg-left" role="gridcell">
                      <a target="_blank" href="https://www.cfjparis.com/fr/hack-the-radio/">Lire l'article </a>
                  </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    );
  }
}
