import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {isBrowser, isMobileOnly, isTablet} from "react-device-detect";
import ReactTooltip from 'react-tooltip'

import addList from '../actions/add_list';

class AddListButton extends Component {

  render() {
    let button;
    if (this.props.icon) {
      button =
        <button
          value={this.props.id}
          data-tip="Ajouter à ma liste"
          onClick={() => this.props.addList(this.props.active_user, this.props.origin, this.props.id)}
          data-action="listAdd"
        >
          <svg viewBox="0 0 58 58" y="0" x="0" width="38" height="38" xmlns="http://www.w3.org/2000/svg" version="1.1">
            <circle id="disc" r="29" cy="29" cx="29" />
            <g id="lines" transform="matrix(1.553719,0,0,1.5523791,12.324854,11.09736)">
              <line className="cls-1" y2="18" x2="13" y1="18" x1="23" />
              <line className="cls-1" y2="13" x2="18" y1="23" x1="18" />
              <line className="cls-1" y2="18" x2="1" y1="18" x1="9" />
              <line className="cls-1" y2="13" x2="1" y1="13" x1="14" />
              <line className="cls-1" y2="8" x2="1" y1="8" x1="18" />
              <line className="cls-1" y2="3" x2="1" y1="3" x1="18" />
            </g>
          </svg>
          <span className="sr-only">Ajouter à ma liste</span>
        </button>;
    } else {
      const {id,active_user,origin} = this.props;
      if (this.props.onPage) {
        button =
          <button
            value={id}
            data-tip="Ajouter à ma liste"
            onClick={() => this.props.addList(active_user, origin, id)}
            data-action="listAdd"
            className="actions__btn col-12 col-sm-5 col-md-12 col-lg-6 d-flex align-items-center justify-content-sm-center justify-content-md-left justify-content-lg-center"
          >
            <svg
              className="actions__ico"
              viewBox="13 13 36 36" y="0" x="0"
              width="38"
              height="38"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1">
              <g id="lines" transform="matrix(1.553719,0,0,1.5523791,12.324854,11.09736)">
                <line className="cls-1" y2="18" x2="13" y1="18" x1="23" />
                <line className="cls-1" y2="13" x2="18" y1="23" x1="18" />
                <line className="cls-1" y2="18" x2="1" y1="18" x1="9" />
                <line className="cls-1" y2="13" x2="1" y1="13" x1="14" />
                <line className="cls-1" y2="8" x2="1" y1="8" x1="18" />
                <line className="cls-1" y2="3" x2="1" y1="3" x1="18" />
              </g>
            </svg>
            <span>Ajouter à ma liste</span>
          </button>
      } else {
        button = (
          <button value={id}
              data-tip="Ajouter à ma liste"
              onClick={() => this.props.addList(active_user, origin, id)}
              data-action="listAdd"
              className="d-flex align-items-center justify-content-sm-center justify-content-md-left justify-content-lg-center">
            <svg viewBox="13 13 36 36"
            y="0"
            x="0"
            width="38"
            height="38"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1">
              <g id="lines"
                transform="matrix(1.553719,0,0,1.5523791,12.324854,11.09736)">
                <line className="cls-1" y2="18" x2="13" y1="18" x1="23" />
                <line className="cls-1" y2="13" x2="18" y1="23" x1="18" />
                <line className="cls-1" y2="18" x2="1" y1="18" x1="9" />
                <line className="cls-1" y2="13" x2="1" y1="13" x1="14" />
                <line className="cls-1" y2="8" x2="1" y1="8" x1="18" />
                <line className="cls-1" y2="3" x2="1" y1="3" x1="18" />
              </g>
            </svg>
            <span>Ajouter à ma liste</span>
          </button>
        );
      }
    }
    return (
      <>
        {button}
        <ReactTooltip
          effect="float"
          place="bottom"
          className="react-tooltip"
          delayShow={300}/>
      </>
    )
  }
}

function mapsStateToProps(state) {
  return {
    active_user: state.active_user,
  };
}
function mapDispatchToProps(dispatch){
  return bindActionCreators({addList}, dispatch);
}
export default connect(mapsStateToProps, mapDispatchToProps, null)(AddListButton);
