import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Slider from "react-slick";

import CardSection from '../components/card_section'
import NextButton from '../components/next_arrow';
import PrevButton from '../components/prev_arrow';


class SliderSection extends Component {

  render() {
    let title, sections, sections_filtred;
    if (this.props.type === "section" ) {
      title = <NavLink to={`/sections`}>Crispy sounds</NavLink>;
      if (this.props.hideFirst){
        sections_filtred = this.props.sections.filter((item, i) => i !== 0 )
      } else {
        const {duration} = item.section;
        const {min, max} = this.props;
        sections_filtred = this.props.sections.filter(item => duration >= min*60).filter(item => duration <= max*60)
      }
      // var sections_filtred = this.props.hideFirst ? this.props.sections.filter((item, i) => i !== 0 ) : this.props.sections.filter(item => item.section.duration >= this.props.min*60).filter(item => item.section.duration <= this.props.max*60)
      sections = sections_filtred.map(item =>
        <CardSection
          id={item.section.id}
          key={`section-` + item.section.id}
          photo={item.section.photo}
          show_photo={true}
          title_website={item.section.title_website}
          description={item.section.description}
          origin="section"
          listen=""
          duration={item.section.duration}
          publication_date={item.section.publication_date}
          source_duration={item.section.source_duration}
          photo_source={item.section.photo_source}
          footer={item.section.footer}
        />
      );
    } else if (this.props.type === "historique") {
      title = "Reprendre l’écoute avec mon profil"
      sections = this.props.historique.map(item =>
        <CardSection
          id={item.section.id}
          key={`historique-` + item.section.id}
          photo={item.section.photo}
          show_photo={false}
          title_website={item.section.title_website}
          description={item.section.description}
          origin="historique"
          listen={Math.round(100*(item.last_user_action.executed_at/(item.section.end_at - item.section.start_at)))}
          duration={item.section.duration - item.last_user_action.executed_at}
          publication_date={item.section.publication_date}
          source_duration={item.section.source_duration}
          photo_source={item.section.photo_source}
          footer={item.section.footer}
        />
      );
    }

    const settings = {
      arrows: true,
      dots: false,
      speed: 200,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      variableWidth: false,
      slide: '.e-card',
      draggable: true,
      nextArrow: <NextButton/>,
      prevArrow: <PrevButton/>,
      mobileFirst: true,
      responsive: [
        {
            breakpoint: 680,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1080,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 1390,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
      ],
    };

    const title_show = this.props.title ? "title--slideshow" : "sr-only"

    return (
      <section className="contents__type contents__type--sections contents__type--sm">
        <h2 className={`title--l2 ${title_show}`}>
          <span>{title}</span>
        </h2>
        <Slider {...settings} className="contents__deck">
          {sections}
        </Slider>
      </section>

    );
  }
}

function mapsStateToProps(state) {
  return {
    sections: state.sections,
    historique: state.historique
  };
}

export default connect(mapsStateToProps, null, null)(SliderSection);
