import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import importSection from '../../actions/pages/import_section'
import importRelatedSections from '../../actions/pages/import_related_sections'

import Headband from '../headband';
import CardSection from '../../components/card_section'

class Section extends Component {
  constructor(props) {
    super(props);
    this.props.importRelatedSections(this.props.active_user, this.props.match.params.id)
    if (_.isEmpty(this.props.sections.filter(item => item.section.id == this.props.match.params.id))) {
      this.props.importSection(this.props.active_user, this.props.match.params.id)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      if (_.isEmpty(this.props.sections.filter(item => item.section.id == this.props.match.params.id))) {
        this.props.importSection(this.props.active_user, this.props.match.params.id)
      }
      this.props.importRelatedSections(this.props.active_user, this.props.match.params.id)
    }
  }

  render() {
    let title = ""
    let visible = "d-none"
    const section = this.props.sections.filter(item => item.section.id == this.props.match.params.id)
    if (!_.isEmpty(section)) {
      title = section[0].section.title_website
    }
    if (!_.isEmpty(this.props.related_sections)) {
      visible = ""
    }
    var style = {margin:0}
    var related_sections = this.props.related_sections.map(item =>
      <CardSection
        id={item.section.id}
        key={`section-` + item.section.id}
        photo={item.section.photo}
        title_website={item.section.title_website}
        description={item.section.description}
        origin="related_section"
        listen=""
        duration={item.section.duration}
      />
    );
    return (
      <main className="page--section" role="main">
        <Headband type="section" id={this.props.match.params.id}/>
        <div className={`contents ${visible} container-fluid`}>
          <section className="contents__type contents__type--sm">
            <h2 className="title--l2" style={style}>
              <span>{`Si vous avez aimé « ${title} »`}</span>
            </h2>
            <div className="contents__deck">
              {related_sections}
            </div>
          </section>
        </div>
      </main>
    );
  }
}
function mapsStateToProps(state) {
  return {
    active_user: state.active_user,
    sections: state.sections,
    related_sections: state.related_sections,
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({importSection, importRelatedSections}, dispatch);
}
export default connect(mapsStateToProps, mapDispatchToProps, null)(Section);
