import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from "react-router-dom";
import _ from 'lodash';

import importPlaylists from '../../actions/pages/import_playlists'

import PlayButtonPresentation from '../play_button_presentation'
import AddListButton from '../add_list_button'
import CardPlaylist from '../../components/card_playlist'

class Playlists extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.importPlaylists(this.props.active_user)
  }

  render() {
    const playlists = this.props.playlists.map(item =>
      <CardPlaylist playlist={item} key={item.playlist.id}/>
    )

    return (
      <main className="page--playlists" role="main">
        <div className="headband container-fluid mb-5">
          <div className="row">
            <div className="headband__introduction d-none d-md-block col-6 py-3">
              Elson vous concocte des playlists de podcast, traitant d'actualités, de thématiques émergentes et de sonorités étranges
            </div>
            <div className="filter col-12 col-md-6"></div>
          </div>
        </div>

        <div className="contents container-fluid">
          <section className="contents__type contents__type--playlists contents__type--lg">
            <h2 className={`title--l2 sr-only title--slideshow`}>
              <span>Les playlists de podcasts</span>
            </h2>
            <div className="contents__deck">
              {playlists}
            </div>
          </section>
        </div>
      </main>
    );
  }
}
function mapsStateToProps(state) {
  return {
    active_user: state.active_user,
    playlists: state.playlists
  };
}
function mapDispatchToProps(dispatch){
  return bindActionCreators({importPlaylists}, dispatch);
}
export default connect(mapsStateToProps, mapDispatchToProps, null)(Playlists);
