import {
  IMPORT_HOME_SECTIONS,
  IMPORT_HOME_DISCONNECTED_SECTIONS,
  IMPORT_PLAYLISTS,
  IMPORT_PLAYLIST,
  IMPORT_SECTIONS_PAGE,
  IMPORT_SECTION,
  IMPORT_SERIES,
  IMPORT_SERIE,
  SET_SPEED,
  CHANGE_SOUND
} from '../actions/types';
// import {IMPORT_COLLECTIONS} from '../actions/pages/import_collections';
// import {IMPORT_COLLECTION} from '../actions/pages/import_collection';
// import {IMPORT_RELATED_SECTIONS} from '../actions/pages/import_related_sections';
// import {IMPORT_HISTORIQUE} from '../actions/pages/import_historique'
// import {IMPORT_LISTE} from '../actions/pages/import_liste'


export default function(state=[], action){
  switch (action.type) {
    case IMPORT_SECTIONS_PAGE :
      if (action.error === true) {
        return state;
      } else {
        return [...state,...action.payload.data.sections_for_player];
      }
    case IMPORT_HOME_SECTIONS :
    case IMPORT_HOME_DISCONNECTED_SECTIONS :
    case IMPORT_SECTION :
      if (action.error === true) {
        return state;
      } else {
        return action.payload.data.sections_for_player;
      }
    case CHANGE_SOUND :
      return (
        state.map(
          (section) => section.section.id === action.payload.previous_sound.section.id ? {...section, last_user_action : {executed_at: action.payload.position} } : section
        )
      )
    case SET_SPEED :
      return (
        state.map(
          (section) => section.section.id === action.payload.section ? {...section, player_speed : action.payload.player_speed } : section
        )
      )
  }
  return state;
}
