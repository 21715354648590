import {
  IMPORT_PLAYLISTS,
  IMPORT_PLAYLIST,
  IMPORT_SECTION,
  IMPORT_SERIES,
  IMPORT_SERIE,
  CHANGE_SOUND
} from '../actions/types';
import {IMPORT_COLLECTIONS} from '../actions/pages/import_collections'
import {IMPORT_COLLECTION} from '../actions/pages/import_collection'
// import {IMPORT_HISTORIQUE} from '../actions/pages/import_historique'
// import {IMPORT_LISTE} from '../actions/pages/import_liste'

export default function(state=[], action){
  switch (action.type) {
    case IMPORT_COLLECTIONS :
    case IMPORT_COLLECTION :
    // case IMPORT_HISTORIQUE :
    // case IMPORT_LISTE :
      if (action.error === true) {
        return state;
      } else {
        return action.payload.data.collections;
      }
  }
  return state;
}
