import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import importCollections from '../../actions/pages/import_collections'
import playPause from '../../actions/play_pause'
import changeSound from '../../actions/change_sound'
import addPile from '../../actions/add_pile'


class Collections extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.importCollections(this.props.active_user)
  }

  handlePile(value) {
    var sound = this.props.sections.filter(item => item.sound.id === value)
    if (!_.isEmpty(sound)) {
      this.props.addPile(sound[0])
      return
    }
  }

  render() {
    var className = this.props.selected_section ? "" : "d-none"
    var sections = this.props.sections.map(item =>
      <div key={item.section.id} className="sections_test">
        <Link to={"/sections/" + item.section.id}> {item.section.card_title} </Link>
        <p value={item.section.id} onClick={() => this.handlePlayPause(item.section.id)} > {(this.props.is_playing && this.props.selected_section.section.id === item.section.id) ? "Pause" : "Play"} </p>
        <p className={className} value={item.section.id} onClick={() => this.handlePile(item.section.id)} > Mettre le son dans la pile (masquer si dans la pile) </p>
    </div>
    )
    return (
      <div>
        <p> Les collections de ELSON </p>
        {sections}
      </div>
    );
  }
}
function mapsStateToProps(state) {
  return {
    active_user: state.active_user,
    selected_section: state.selected_section,
    next_sections: state.next_sections,
    sections: state.sections,
    is_playing: state.is_playing,
    first_sound: state.first_sound
  };
}
function mapDispatchToProps(dispatch){
  return bindActionCreators({importCollections,playPause,changeSound, addPile}, dispatch);
}
export default connect(mapsStateToProps, mapDispatchToProps, null)(Collections);
