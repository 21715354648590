import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import Navbar from '../components/navbar';
import Contenu from '../components/contenu';
import Player from '../components/player';
import ScrollToTop from '../components/scroll_top';

import setUser from '../actions/set_user'
import setDefautSection from '../actions/set_defaut_section'

// L'application est rendue dans la view via la class "react"
// Elle est composée de 3 éléments : la navbar, le contenu de page et le player

class App extends Component {
  constructor(props) {
    super(props);
    this.props.setUser(this.props.user)
    this.props.setDefautSection(this.props.user)
  }

  render() {
    return (
      <Router>
        <ScrollToTop>
          <Navbar/>
          <Contenu/>
          <Player/>
        </ScrollToTop>
      </Router>
    );
  }
}
function mapDispatchToProps(dispatch){
  return bindActionCreators({setUser, setDefautSection}, dispatch);
}
export default connect(null, mapDispatchToProps, null)(App);
