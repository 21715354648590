import {SET_DEFAULT_SECTION} from './types';
import axios from 'axios';
import URL from '../helpers/helper_url'

export default function setDefautSection(user_id) {
  const url = URL + `/presentation_section_fr`;
  var params = {
    user_id: user_id,
  }
  const request = axios.post(url, params);

  return {
    type: SET_DEFAULT_SECTION,
    payload: request
  };
};
