export const ADD_LIST = 'ADD_LIST';
export const ADD_PILE = 'ADD_PILE';
export const CHANGE_LIST_ORDER = 'CHANGE_LIST_ORDER';
export const CHANGE_SOUND_STATUS = 'CHANGE_SOUND_STATUS';
export const CHANGE_SOUND = 'CHANGE_SOUND';
export const ENDED = 'ENDED';
export const HAS_MORE_SECTIONS = 'HAS_MORE_SECTIONS';
export const IMPORT_HOME_HISTORIQUE = 'IMPORT_HOME_HISTORIQUE';
export const IMPORT_HOME_SECTIONS = 'IMPORT_HOME_SECTIONS';
export const IMPORT_HOME_DISCONNECTED_SECTIONS = 'IMPORT_HOME_DISCONNECTED_SECTIONS';
export const IMPORT_HOME_DISCONNECTED_PLAYLISTS = 'IMPORT_HOME_DISCONNECTED_PLAYLISTS';
export const IMPORT_HOME_DISCONNECTED_SERIES = 'IMPORT_HOME_DISCONNECTED_SERIES';
export const IMPORT_HOME_PLAYLISTS = 'IMPORT_HOME_PLAYLISTS';
export const IMPORT_HOME_SERIES = 'IMPORT_HOME_SERIES';
export const IMPORT_LISTE = 'IMPORT_LISTE';
export const IMPORT_PLAYLIST = 'IMPORT_PLAYLIST';
export const IMPORT_PLAYLISTS = 'IMPORT_PLAYLISTS';
export const IMPORT_RELATED_SECTIONS = 'IMPORT_RELATED_SECTIONS';
export const IMPORT_SECTIONS_PAGE = 'IMPORT_SECTIONS_PAGE';
export const IMPORT_SECTION = 'IMPORT_SECTION';
export const IMPORT_SERIE = 'IMPORT_SERIE';
export const IMPORT_SERIES = 'IMPORT_SERIES';
export const INCREASE_ACTIVE_SECTIONS_PAGE_NR = 'INCREASE_ACTIVE_SECTIONS_PAGE_NR';
export const JUMP = 'JUMP';
export const IS_FETCHING_SECTIONS = 'IS_FETCHING_SECTIONS';
export const PLAY_PAUSE = 'PLAY_PAUSE';
export const REMOVE_LIST = 'REMOVE_LIST';
export const RESET_NEXT_SECTIONS = 'RESET_NEXT_SECTIONS';
export const SET_DEFAULT_SECTION = 'SET_DEFAULT_SECTION';
export const SET_POSITION = 'SET_POSITION';
export const SET_SPEED = 'SET_SPEED';
export const SET_USER = 'SET_USER';
export const SHOW_INFO = 'SHOW_INFO';
export const TIME_UPDATE = 'TIME_UPDATE';


// export const IMPORT_COLLECTION = 'IMPORT_COLLECTION';
// export const IMPORT_COLLECTIONS = 'IMPORT_COLLECTIONS';
// export const IMPORT_SECTIONS = 'IMPORT_SECTIONS';
