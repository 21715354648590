import {IMPORT_SERIES} from '../types';
import axios from 'axios';
import URL from '../../helpers/helper_url'

export default function importSeries(user_id) {
  console.log("Import series")
  const url = URL + `/series`;
  const request = axios.get(url, {params: {user_id: user_id}});

  return {
    type: IMPORT_SERIES,
    payload: request
  };
};
