import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import ReduxPromise from 'redux-promise';
import thunk from 'redux-thunk';
import logger from 'redux-logger'

import App from './containers/app';
import AppDisconnected from './containers/app_disconnected';
import Player from './components/player';
import HomeDisconnected from './containers/contenu/home_disconnected';
import reducers from './reducers';

import Raven from 'raven-js';

if(process.env.ENVIRONNEMENT == 'production'){
  Raven.config('https://754436af921a4415b4be2870ede5f793@sentry.io/1253505').install()
}

let store;

if(process.env.NODE_ENV == 'development'){
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  store =  createStore(
    reducers,
    composeEnhancers(applyMiddleware(ReduxPromise, thunk))
  );
} else {
  const createStoreWithMiddleware = applyMiddleware(ReduxPromise, thunk)(createStore);
  store = createStoreWithMiddleware(reducers);
}

const react_home = document.querySelector('.react-home');
if (react_home) {
  ReactDOM.render(
    <Provider store={store}>
      <AppDisconnected />
    </Provider>
    , react_home);
}

const react = document.querySelector('.react');
if (react) {
  const user = JSON.parse(react.dataset.user);
  ReactDOM.render(
    <Provider store={store}>
      <App user={user} />
    </Provider>
    , react);
}
