import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import Player from '../components/player';
import HomeDisconnected from './contenu/home_disconnected';

import setDefautSection from '../actions/set_defaut_section'

// L'application est rendue dans la view via la class "react"
// Elle est composée de 3 éléments : la navbar, le contenu de page et le player

class AppDisconnected extends Component {
  constructor(props) {
    super(props);
    this.props.setDefautSection(1)
  }

  render() {
    return (
      <React.Fragment>
        <HomeDisconnected/>
        <Player/>
      </React.Fragment>
    );
  }
}
function mapDispatchToProps(dispatch){
  return bindActionCreators({setDefautSection}, dispatch);
}
export default connect(null, mapDispatchToProps, null)(AppDisconnected);
