import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { NavLink } from "react-router-dom";

import importSeries from '../../actions/pages/import_series'
import playPause from '../../actions/play_pause'
import changeSound from '../../actions/change_sound'
import addPile from '../../actions/add_pile'

import SliderSerie from '../slider_serie'
import AddListButton from '../../containers/add_list_button';

import formatTime from '../../helpers/helper_time';


class Series extends Component {

  componentDidMount() {
    this.props.importSeries(this.props.active_user)
  }

  renderHTML(value) {
    return ( { __html: value} )
  }

  formatTimeToConvert(item){
    let duration = 0
    item.sections.map(section =>
      duration += section.section.duration
    )
    return formatTime(duration)
  }

  render() {
    // var className = this.props.selected_section ? "" : "d-none"
    const series = this.props.series.map(item =>
      <div className="e-card card--series card--title-opacity" key={`serie-` + item.serie.id}>
        <div className="card__title" style={{backgroundImage: `url(${item.serie.photo})`}}>
          <div className="title__holder">
            <h3 className="title__text text-truncate">
              <NavLink to={`/series/${item.serie.id}`}>{item.serie.name}</NavLink>
            </h3>
          </div>
        </div>
        <div className="card__body">
          <div className="card__description card__description--cropped" dangerouslySetInnerHTML={this.renderHTML(item.serie.description)}></div>
          <div className="actions card__actions">
            <div className="actions__sound actions">
              <AddListButton id={item.serie.id} origin="serie" icon={true}/>
            </div>
            <NavLink className="collapsed" to={`/series/${item.serie.id}`}  data-toggle="">
              <span className="sr-only">Lire plus…</span>
            </NavLink>
          </div>
        </div>
      </div>
    );
    return (
      <div>
        <main className="page--series" role="main">
          <div className="headband container-fluid">
            <div className="row">
              <div className="headband__introduction d-none d-md-block col-6 py-3">
                Retrouvez ici une sélection de séries du réel (documentaires) et de fictions. Et lorsque l’on dégote des perles sonore qui mériteraient une série, on en crée une pour vous !              </div>
              <div className="filter col-12 col-md-6">
                <div className="row p-3 px-sm-5 py-sm-4">
                  <div className="filter__legend col-3 d-none d-sm-block"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="contents container-fluid">
            <section className="contents__type contents__type--md">
                <h2 className="title--l2 sr-only">
                  <span>Series</span>
                </h2>
                <div className="contents__deck">
                  {series}
                </div>
            </section>
          </div>
        </main>
      </div>
    );
  }
}
function mapsStateToProps(state) {
  return {
    active_user: state.active_user,
    selected_section: state.selected_section,
    next_sections: state.next_sections,
    sections: state.sections,
    is_playing: state.is_playing,
    first_sound: state.first_sound,
    series: state.series
  };
}
function mapDispatchToProps(dispatch){
  return bindActionCreators({importSeries,playPause,changeSound}, dispatch);
}
export default connect(mapsStateToProps, mapDispatchToProps, null)(Series);
