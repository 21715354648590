import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from 'react-loader-spinner';
import _ from 'lodash';

import {importSectionsPage} from '../../actions/pages/import_sections_page'

import CardSection from '../../components/card_section'

class Sections extends Component {
  state = {
    sections:[],
    min: 0,
    max: 300,
    itemsCountPerPage: 12,
    active_sections_page_nr:1
  }

  sliderChange = () => {
    const values = $( "#filter--sections" ).slider( "values" );
    let min = Math.min(values[0], values[values.length - 1])
    let max = Math.max(values[0], values[values.length - 1])
    if (min === max) {
      min -= 5; max += 5 ;
    } else if (max === 100) {
      max = 300 ;
    }
    this.setState({min, max, active_sections_page_nr: 1})
  }

  componentDidMount() {
    $("#filter--sections").slider({
      values: [0, 100],
      step: 5,
      min: 0,
      max: 100,
      change: this.sliderChange
    });
    this.props.importSectionsPage(
      this.props.active_user,
      this.state.min,
      this.state.max,
      0,
      this.state.itemsCountPerPage
    )
  }

  loadMore = () => {
    console.log('loadMore call');
    if (this.props.sections.length === 0 || this.props.is_fetching_sections) {
      return
    }

    this.props.importSectionsPage(
      this.props.active_user,
      this.state.min,
      this.state.max,
      this.props.active_sections_page_nr,
      this.state.itemsCountPerPage
    )
  }

  filterByDuration = (aState) => {
    const {min, max} = aState
    const filtered_sections = this.props
                                  .sections
                                  .filter(item => item.section.duration >= min * 60)
                                  .filter(item => item.section.duration <= max * 60)
    return filtered_sections
  }

  render() {
    var className = this.props.selected_section ? "" : "d-none";
    const sections_filtred = this.filterByDuration(this.state);
    const sections = sections_filtred.map(item => {
      const {
        id,
        photo,
        title_website,
        description,
        duration,
        publication_date,
        source_duration,
        photo_source,
        footer
      } = item.section
      return(<CardSection
        id={id}
        key={`section-${id}`}
        photo={photo}
        show_photo={true}
        title_website={title_website}
        description={description}
        origin="section"
        listen=""
        duration={duration}
        publication_date={publication_date}
        source_duration={source_duration}
        photo_source={photo_source}
        footer={footer}
      />
      )}
    );
    const loader = <div className="loader" key={0}>
      <Loader
        type="Audio"
        color="#F74E37"
        height="55"
        width="70"
        />
    </div>;
    return (
      <main className="page--sections" role="main">
        <div className="contents container-fluid">
          <div className="headband mb-5 row">
            <div className="headband__introduction d-none d-md-block col-6 py-3">
              Elson sélectionne chaque jour des sons traitant d'actualité
              ou de sujets émergents. Vous
              pouvez faire une sélection par durée grâce au
              filtre, ou laisser agir votre curiosité.
            </div>
            <div className="filter col-12 col-md-6">
              <div className="row p-3 px-sm-5 py-sm-4">
                <div className="filter__legend col-3 d-none d-sm-block">
                  Durée (minutes)
                </div>
                <div className="filter__slider col-12 col-sm-9">
                  <ul className="filter__labels">
                    <li className="filter__label filter__label--0">0</li>
                    <li className="filter__label filter__label--5">5</li>
                    <li className="filter__label filter__label--10">10</li>
                    <li className="filter__label filter__label--20">20</li>
                    <li className="filter__label filter__label--30">30</li>
                    <li className="filter__label filter__label--45">45</li>
                    <li className="filter__label filter__label--60">60</li>
                    <li className="filter__label filter__label--90">90</li>
                    <li className="filter__label filter__label--more">+90</li>
                  </ul>
                  <div id="filter--sections" className="filter__track"></div>
                </div>
              </div>
            </div>
          </div>
          <section className="contents__type contents__type--sections contents__type--sm">
            <h2 className={`title--l2 sr-only`}>
              <span>"Crispy sounds"</span>
            </h2>
            <div className="scroll-container">
              <InfiniteScroll
                pageStart={1}
                loadMore={this.loadMore}
                hasMore={this.props.has_more_sections}
                loader={loader}
                useWindow={true}
                threshold={250}
              >
                <div className="contents__deck">
                  {sections}
                </div>
              </InfiniteScroll>
            </div>
          </section>
        </div>
      </main>
    )
  }
}

function mapsStateToProps(state) {
  return {
    items_count_per_page : state.itemsCountPerPage,
    active_user: state.active_user,
    selected_section: state.selected_section,
    next_sections: state.next_sections,
    is_playing: state.is_playing,
    sections: state.sections,
    first_sound: state.first_sound,
    is_fetching_sections: state.is_fetching_sections,
    active_sections_page_nr: state.active_sections_page_nr,
    has_more_sections: state.has_more_sections
  };
  // sections_active_page: state.sectionsActivePage,
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    importSectionsPage
  }, dispatch);
}
// const importedFunctions = { importSectionsPage }
export default connect(mapsStateToProps, mapDispatchToProps)(Sections);
