import React, { Component } from 'react';

export default function NextButton({ onClick }) {
  return (
    <button className="btn btn-link slick-arrow" onClick={onClick} data-action="slide">
      <svg viewBox="0 0 21 70" height="70" width="21">
        <path d="m 1,1 19,34 -19,34" />
      </svg>
    </button>
  )
}
