import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {isBrowser, isMobileOnly, isTablet} from "react-device-detect";

import playPause from '../actions/play_pause';
import changeSound from '../actions/change_sound';
import setPosition from '../actions/set_position';
import resetNextSections from '../actions/reset_next_sections';

class PlayButtonPresentation extends Component {
  constructor(props) {
    super(props);
  }

  // Value est l'id de la playlist ou de la serie dont on veut lancer la présentation
  handlePlayPause(value) {
    let section;
    const audio_html_dom = document.getElementById("audio-player");
    const position = audio_html_dom.currentTime;
    const action = this.props.is_playing ? "pause" : "play"
    // Selection de la section de présentation
    if (this.props.origin === "playlist") {
      const playlist = this.props.playlists.filter(item => item.playlist.id == value)[0]
      section = playlist.presentation_section
    } else if (this.props.origin === "serie"){
      const serie = this.props.series.filter(item => item.serie.id == value)[0]
      section = serie.presentation_section
    }
    // Si le son est celui écouté ou non, on fait un play/pause, sinon on change le son
    if (this.props.selected_section.section.id === section.section.id) {
      this.props.is_playing ? audio_html_dom.pause() : audio_html_dom.play()
      this.props.playPause(this.props.active_user, this.props.selected_section.section.id, action, position, null, null)
    } else {
      this.props.resetNextSections()
      if (this.props.is_playing && !this.props.first_sound){
        this.props.playPause(this.props.active_user, this.props.selected_section.section.id, action, position, null, null)
      }
      this.props.changeSound(section, this.props.selected_section, position)
      this.props.setPosition(0)
    }
  }

  render() {
    let source_id, button;
    if (this.props.id) {
      source_id = this.props.id
    } else {
      source_id = this.props.selected_section ? this.props.selected_section.section.id : 0
    };
    if (this.props.onPage) {
      button =
        <button className="actions__btn col-12 col-sm-7 col-md-12 col-lg-6 d-flex align-items-center justify-content-sm-center justify-content-md-left justify-content-lg-center" value={this.props.id} onClick={() => this.handlePlayPause(this.props.id)} data-action="play">
          <svg className="actions__ico" viewBox="0 0 381 413" height="42" width="42">
            <path d="m 359.5,242.38242 c -61,50 -214,139 -288,166 -36,13 -54,3 -61,-36 -14,-77 -14,-254 0,-330.999999 7,-38.9999997 25,-48.9999997 61,-35.9999997 74,26.9999997 227,114.9999987 288,165.9999987 29,25 29,46 0,71 z m -28,-36 c 0,20 -221,150 -290,176 0,2 1,3 2,4 19,11 311,-157 311,-180 0,-22 -292,-189.999999 -311,-178.999999 -1,1 -2,2 -2,4 69,25 290,155.999999 290,174.999999 z" />
          </svg>
          <span>Écouter la présentation d’elson</span>
        </button>;
    } else if (this.props.onHome) {
      if (this.props.is_playing && this.props.origin === "playlist" && this.props.playlists.length > 0 && this.props.selected_section.section.id === this.props.playlists[0].presentation_section.section.id) {
        button = (
          <button value={source_id} className={`d-flex align-items-center`} data-action="play" onClick={() => this.handlePlayPause(source_id)}>
            <span className="sr-only">pause</span>
            <svg width={42} height={42} viewBox="0 0 847 847"><path d="M16 423c0,-225 182,-407 407,-407 225,0 408,182 408,407 0,225 -183,408 -408,408 -225,0 -407,-183 -407,-408zm248 159l0 -317c0,-24 19,-43 42,-43l59 0c23,0 42,19 42,43l0 317c0,23 -19,42 -42,42l-59 0c-23,0 -42,-19 -42,-42zm28 -10l0 -317c0,-4 2,-8 3,-12 -7,4 -13,12 -13,22l0 317c0,13 11,24 24,24l59 0c9,0 17,-5 21,-12 -4,1 -7,2 -11,2l-58 0c-14,0 -25,-11 -25,-24zm148 10l0 -317c0,-24 19,-43 42,-43l58 0c24,0 43,19 43,43l0 317c0,23 -19,42 -43,42l-58 0c-23,0 -42,-19 -42,-42zm28 -10l0 -317c0,-4 1,-8 3,-12 -8,4 -13,12 -13,22l0 317c0,13 11,24 24,24l58 0c9,0 17,-5 21,-12 -3,1 -7,2 -11,2l-58 0c-13,0 -24,-11 -24,-24z"/></svg>
          </button>);
      } else if (this.props.is_playing && this.props.origin === "serie" && this.props.series.length > 0 && this.props.selected_section.section.id === this.props.series[0].presentation_section.section.id) {
        button = (
          <button value={source_id} className={`d-flex align-items-center`} data-action="play" onClick={() => this.handlePlayPause(source_id)}>
            <span className="sr-only">pause</span>
            <svg width={42} height={42} viewBox="0 0 847 847"><path d="M16 423c0,-225 182,-407 407,-407 225,0 408,182 408,407 0,225 -183,408 -408,408 -225,0 -407,-183 -407,-408zm248 159l0 -317c0,-24 19,-43 42,-43l59 0c23,0 42,19 42,43l0 317c0,23 -19,42 -42,42l-59 0c-23,0 -42,-19 -42,-42zm28 -10l0 -317c0,-4 2,-8 3,-12 -7,4 -13,12 -13,22l0 317c0,13 11,24 24,24l59 0c9,0 17,-5 21,-12 -4,1 -7,2 -11,2l-58 0c-14,0 -25,-11 -25,-24zm148 10l0 -317c0,-24 19,-43 42,-43l58 0c24,0 43,19 43,43l0 317c0,23 -19,42 -43,42l-58 0c-23,0 -42,-19 -42,-42zm28 -10l0 -317c0,-4 1,-8 3,-12 -8,4 -13,12 -13,22l0 317c0,13 11,24 24,24l58 0c9,0 17,-5 21,-12 -3,1 -7,2 -11,2l-58 0c-13,0 -24,-11 -24,-24z"/></svg>
          </button>);
      } else {
        button = (
          <button value={source_id} className={`d-flex align-items-center`} data-action="play" onClick={() => this.handlePlayPause(source_id)}>
            <span className="sr-only">play</span>
            <svg width={42} height={42} viewBox="0 0 847 847"  version="1.0" ><path d="M423 19c223,0 404,181 404,404 0,223 -181,404 -404,404 -223,0 -404,-181 -404,-404 0,-223 181,-404 404,-404zm169 440c-61,50 -214,139 -288,166 -36,13 -54,3 -61,-36 -14,-77 -14,-254 0,-331 7,-39 25,-49 61,-36 74,27 227,115 288,166 29,25 29,46 0,71zm-28 -36c0,20 -221,150 -290,176 0,2 1,3 2,4 19,11 311,-157 311,-180 0,-22 -292,-190 -311,-179 -1,1 -2,2 -2,4 69,25 290,156 290,175zm-141 -372c211,0 383,169 387,379 0,-2 0,-4 0,-7 0,-213 -173,-386 -387,-386 -213,0 -386,173 -386,386 0,3 0,5 0,7 4,-210 175,-379 386,-379z"/></svg>
          </button>);
      }
    } else {
      button =
        <button className="d-flex align-items-center justify-content-sm-center justify-content-md-left justify-content-lg-center" value={this.props.id} onClick={() => this.handlePlayPause(this.props.id)} data-action="play">
          <svg viewBox="0 0 381 413" x="0" y="0" height="42" width="42">
            <path d="m 359.5,242.38242 c -61,50 -214,139 -288,166 -36,13 -54,3 -61,-36 -14,-77 -14,-254 0,-330.999999 7,-38.9999997 25,-48.9999997 61,-35.9999997 74,26.9999997 227,114.9999987 288,165.9999987 29,25 29,46 0,71 z m -28,-36 c 0,20 -221,150 -290,176 0,2 1,3 2,4 19,11 311,-157 311,-180 0,-22 -292,-189.999999 -311,-178.999999 -1,1 -2,2 -2,4 69,25 290,155.999999 290,174.999999 z" />
          </svg>
          <span>Écouter la présentation d’elson</span>
        </button>;
    }
    return (
      button
    )
  }
}

function mapsStateToProps(state) {
  return {
    active_user: state.active_user,
    selected_section: state.selected_section,
    is_playing: state.is_playing,
    playlists: state.playlists,
    series: state.series,
    first_sound: state.first_sound
  };
}
function mapDispatchToProps(dispatch){
  return bindActionCreators({playPause, changeSound, setPosition, resetNextSections}, dispatch);
}
export default connect(mapsStateToProps, mapDispatchToProps, null)(PlayButtonPresentation);
