import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { NavLink } from "react-router-dom";

import importSerie from '../../actions/pages/import_serie'
import playPause from '../../actions/play_pause'
import changeSound from '../../actions/change_sound'
import addPile from '../../actions/add_pile'

import PlayButton from '../play_button';
import Headband from '../headband';
import formatTime from '../../helpers/helper_time';

class Serie extends Component {
  constructor(props) {
    super(props);
    if (_.isEmpty(this.props.series.filter(item => item.serie.id == this.props.match.params.id))) {
      this.props.importSerie(this.props.active_user, this.props.match.params.id)
    }
  }

  renderHTML(value) {
    return ( { __html: value} )
  }

  calcRemainingTime(item) {
    const remaining_time = parseInt(item.last_user_action.executed_at * 100 / item.section.duration)
    return `${remaining_time}%`
  }


  render() {
    let serie, duration, sections;
    if (!_.isEmpty(this.props.series)) {
      serie = this.props.series.filter(item => item.serie.id == this.props.match.params.id)[0].serie
      // var sections_number = this.props.series.filter(item => item.serie.id == this.props.match.params.id)[0].sections.length
      duration = 0
      this.props.series.filter(item => item.serie.id == this.props.match.params.id)[0].sections.map(item =>
        duration += item.section.duration
      )
      sections = this.props.series.filter(item => item.serie.id == this.props.match.params.id)[0].sections.map((item, i)  =>
        <div id={item.section.id} key={item.section.id} className="list__item row no-gutters align-items-center">
          <div className="col-11 col-sm-6 col-md-5 col-lg-4 d-flex align-items-center">
            <div className="actions mr-2">
              <PlayButton id={item.section.id} origin="serie" origin_id={this.props.match.params.id}/>
            </div>
            <span className="item__number d-none d-sm-block mr-3">{i + 1}</span>
            <h3 className="item__title text-truncate">
              {item.section.title_website}
            </h3>
          </div>
          {/*@todo trouver solution pour éviter balise p et la margin*/}
          <div className="col-12 col-sm-5 col-md-6 col-lg-4 order-3 order-sm-2 mt-2 mt-sm-0 text-truncate" dangerouslySetInnerHTML={this.renderHTML(item.section.description)}>
          </div>
          <div className="col-1 col-sm-1 order-2 order-sm-3 text-center">
            <NavLink className="item__more" to={`/sections/${item.section.id}`}>
              <span className="sr-only">
                Plus d’</span>i<span className="sr-only">nformation
              </span>
            </NavLink>
          </div>
          <div className="d-none d-lg-block col-lg-1 order-5 text-right">
            <span className="sr-only">temps</span>
            <span className="d-lg-none">restant : </span>
            {formatTime(item.section.end_at - item.last_user_action.executed_at)}
          </div>
          <div className="d-none d-lg-block col-lg-1 order-lg-6 text-right">
            <span className="d-lg-none">
              <span className="sr-only">temps</span> total&nbsp;:
            </span>
            {formatTime(item.section.end_at - item.section.start_at)}
          </div>
          <div className="list__progress col-12 col-lg flex-grow-1 order-5 order-sm-8 order-lg-4 mt-sm-2 mt-lg-0 ">
            <div className="progress rounded-0">
              <div className="progress-bar" role="progressbar" aria-valuenow="88" aria-valuemin="0" aria-valuemax="100"  style={{width: this.calcRemainingTime(item)}} >
                <div className="progress__time--combo d-lg-none">
                  <span className="sr-only">temps </span> restant&nbsp;:
                  {this.calcRemainingTime(item)}
                  <span title="sur"> ou </span>
                  {formatTime(item.section.end_at - item.section.start_at)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      serie = {}
      duration = 0
    }
    return (
      <main className="page--serie" role="main">
        <Headband type="serie" id={this.props.match.params.id} />
        <div className="contents container">
          <section className="contents__type">
            <h2 className="title--l2 sr-only">
              <span>Sons de la série «&nbsp;{serie.name}&nbsp;»</span>
            </h2>
            <div className="contents__list">
              <ul className="list__headers d-none d-lg-flex row no-gutters list-unstyled">
                <li className="col-3 offset-1">Titre</li>
                <li className="col-2">Description</li>
                <li className="col-1 offset-4 text-right">Restant</li>
                <li className="col-1 text-right">Total</li>
              </ul>
              {sections}
            </div>
          </section>
        </div>
      </main>
    );
  }
}
function mapsStateToProps(state) {
  return {
    active_user: state.active_user,
    selected_section: state.selected_section,
    next_sections: state.next_sections,
    sections: state.sections,
    is_playing: state.is_playing,
    first_sound: state.first_sound,
    series: state.series
  };
}
function mapDispatchToProps(dispatch){
  return bindActionCreators({importSerie,playPause,changeSound, addPile}, dispatch);
}
export default connect(mapsStateToProps, mapDispatchToProps, null)(Serie);
