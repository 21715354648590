import React, { Component } from 'react';

export default class Cgu extends Component {

  render() {
    return (
      <main className="page--static page--cgu" role="main">
              <div className="
                  headband
                  e-card
                  card--title-middle
                  card--plain
                  card--title-play
                  container-fluid
              ">
                  <div className="row">
                      <div className="headband__img card__title col-12 col-md-5 col-lg-4">
                          <div className="title__holder">
                              <h2 className="title__text">
                                  Mentions légales &amp; conditions générales d’utilisation
                              </h2>
                          </div>
                      </div>
                      <div className="
                          headband__info
                          col-12 col-md-7 col-lg-8
                          d-flex flex-column justify-content-between
                      ">
                          <div className="headband__metas row align-items-baseline"></div>
                          <div className="headband__description p-3 p-lg-5 row">
                              <div className="col p-0">
                                  <p>Vous avez envie d’en savoir plus sur les dessous d’elson ? N’hésitez pas à nous poser vos questions à hello@elson.fr.</p>
                                  <p>Et sinon, voici nos mentions légales et CGU.</p>
                              </div>
                          </div>
                          <div className="headband__actions actions row"></div>
                      </div>
                  </div>
              </div>


              <div className="contents container">
                  <h3 className="title--l2">Mentions légales</h3>

                  <p>Le site internet elson.fr est édité par <i className="text-nowrap">Hack the Radio</i>, Société par actions simplifiées au capital social de 4000 euros. RCS Paris n° 839 723 657</p>
                  <p>Son siège social est au 43 rue de Meaux - 75019 Paris.</p>
                  <p>
                      <strong>Contact&nbsp;:</strong>
                      <a href="mailto:carine%40elson%2Efr">
                          <span>carine</span>&#64;<span>elson.fr</span>
                      </a>
                  </p>

                  <p><strong>Directrice de publication&nbsp;:</strong> Carine Fillot</p>

                  <p>Le site internet elson.fr est hébergé par la société Heroku Inc., 650 7th Street, San Francisco, CA (tel&nbsp;: +33 1 (877) 563-4311).</p>


                  <h3 className="title--l2">Conditions générales d’utilisation</h3>


                  <h4
                      id="cgu-objet"
                      className="title--l3"
                  >
                      Objet
                  </h4>

                  <p>
                      Les présentes conditions générales ont pour objet de définir les modalités et conditions d’utilisation des services proposés sur le site (ci-après&nbsp;: les «&nbsp;<i>Services</i>&nbsp;»), ainsi que de définir les droits et obligations des parties dans ce cadre.
                  </p>
                  <p>
                      Elles peuvent être complétées, le cas échéant, par des conditions d’utilisation particulières à certains <i>Services</i>. En cas de contradiction, les conditions particulières prévalent sur ces conditions générales.
                  </p>

                  <h4
                      id="cgu-exploitant-des-services"
                      className="title--l3"
                  >
                      Exploitant des <i>Services</i>
                  </h4>

                  <p>
                      Les <i>Services</i> de elson.fr sont exploités par la société <i className="text-nowrap">Hack the Radio</i>, société par actions simplifiées au capital social de 4000 euros, immatriculée au RCS de Paris sous le n° 839 723 657, dont le siège social est situé 43 rue de Meaux 75019 Paris (ci-après&nbsp;: «&nbsp;<i className="text-nowrap">Hack the Radio</i>&nbsp;»).
                  </p>
                  <p>
                      <i className="text-nowrap">Hack the Radio</i> peut être contactée aux coordonnées suivantes&nbsp;:
                  </p>
                  <ul>
                      <li>
                          <strong>Adresse postale&nbsp;:</strong>
                          elson / <i className="text-nowrap">hack the radio</i> 43 rue de meaux 75019 paris
                      </li>

                      <li>
                          <strong>Adresse électronique&nbsp;:</strong>
                          <a href="mailto:hello%40elson%2Efr">
                              <span>hello</span>&#64;<span>elson.fr</span>
                          </a>
                      </li>
                  </ul>

                  <h4
                      id="cgu-acces-au-site-et-services"
                      className="title--l3"
                  >
                      Accès au site et aux <i>Services</i>
                  </h4>

                  <p>Les <i>Services</i> sont accessibles, sous réserve des restrictions prévues sur le site&nbsp;:</p>

                  <ul>
                      <li>
                          à toute personne physique disposant de la pleine capacité juridique pour s’engager au titre des présentes conditions générales. La personne physique qui ne dispose pas de la pleine capacité juridique ne peut accéder au Site et aux <i>Services</i> qu’avec l’accord de son représentant légal&nbsp;;
                      </li>
                      <li>
                          à toute personne morale agissant par l’intermédiaire d’une personne physique disposant de la capacité juridique pour contracter au nom et pour le compte de la personne morale.
                      </li>
                  </ul>

                  <h4
                      id="cgu-acceptation-des-conditions-generales"
                      className="title--l3"
                  >
                      Acceptation des conditions générales
                  </h4>

                  <p>L’acceptation des présentes conditions générales est matérialisée par une case à cocher dans le formulaire d’inscription. Cette acceptation ne peut être que pleine et entière. Toute adhésion sous réserve est considérée comme nulle et non avenue. L’Utilisateur qui n’accepte pas d’être lié par les présentes conditions générales ne doit pas utiliser les <i>Services</i>.</p>


                  <h4
                      id="cgu-inscription-sur-le-site"
                      className="title--l3"
                  >
                      Inscription sur le site
                  </h4>

                  <ul className="list--l2">
                      <li>
                          <p>
                              L’utilisation des <i>Services</i> nécessite que l’Utilisateur s’inscrive sur le site, en remplissant le formulaire prévu à cet effet. L’Utilisateur doit fournir l’ensemble des informations marquées comme obligatoires. Toute inscription incomplète ne sera pas validée.
                          </p>

                          <p>
                              L’inscription entraîne automatiquement l’ouverture d’un compte au nom de l’Utilisateur (ci-après&nbsp;: le «&nbsp;<i>Compte</i>&nbsp;»), lui donnant accès à un espace personnel (ci-après&nbsp;: l’«&nbsp;<i>Espace Personnel</i>&nbsp;») qui lui permet de gérer son utilisation des <i>Services</i> sous une forme et selon les moyens techniques que <i className="text-nowrap">Hack the Radio</i> juge les plus appropriés pour rendre lesdits <i>Services</i>.
                          </p>

                          <p>
                              L’Utilisateur garantit que toutes les informations qu’il donne dans le formulaire d’inscription sont exactes, à jour et sincères et ne sont entachées d’aucun caractère trompeur.
                          </p>

                          <p>
                              Il s’engage à mettre à jour ces informations dans son <i>Espace Personnel</i> en cas de modifications, afin qu’elles correspondent toujours aux critères susvisés.
                          </p>

                          <p>
                              L’Utilisateur est informé et accepte que les informations saisies aux fins de création ou de mise à jour de son <i>Compte</i> vaillent preuve de son identité. Les informations saisies par l’Utilisateur l’engagent dès leur validation.
                          </p>
                      </li>
                      <li>
                          <p>
                              L’Utilisateur peut accéder à tout moment à son <i>Espace Personnel</i> après s’être identifié à l’aide de son identifiant de connexion ainsi que de son mot de passe.
                          </p>

                          <p>
                              L’Utilisateur s’engage à utiliser personnellement les <i>Services</i> et à ne permettre à aucun tiers de les utiliser à sa place ou pour son compte, sauf à en supporter l’entière responsabilité.
                          </p>

                          <p>
                              Il est pareillement responsable du maintien de la confidentialité de son identifiant et de son mot de passe. Il doit immédiatement contacter <i className="text-nowrap">Hack the Radio</i> aux coordonnées mentionnées à l’<a href="#cgu-exploitant-des-services">article 2</a> des présentes Conditions s’il remarque que son <i>Compte</i> a été utilisé à son insu. Il reconnaît à <i className="text-nowrap">Hack the Radio</i> le droit de prendre toutes mesures appropriées en pareil cas.
                          </p>
                      </li>
                  </ul>

                  <h4
                      id="cgu-description-des-services"
                      className="title--l3"
                  >
                      Description des <i>Services</i>
                  </h4>

                  <p>
                      L’Utilisateur a accès aux <i>Services</i> décrits sur le site, sous une forme et selon les fonctionnalités et moyens techniques que <i className="text-nowrap">Hack the Radio</i> juge les plus appropriés.
                  </p>

                  <h4
                      id="cgu-services-gratuits"
                      className="title--l3"
                  >
                      <i>Services</i> gratuits
                  </h4>

                  <p>
                      Les <i>Services</i> sont actuellement fournis à titre gratuit.
                  </p>

                  <h4
                      id="cgu-donnees"
                      className="title--l3"
                  >
                      Données
                  </h4>

                  <p>
                      L’Utilisateur reconnaît et accepte expressément&nbsp;:
                  </p>

                  <ol className="list--roman">
                      <li>
                          que les données recueillies sur le site et sur les équipements informatiques de <i className="text-nowrap">Hack the Radio</i> font foi de la réalité des opérations intervenues dans le cadre des présentes&nbsp;;
                      </li>
                  </ol>

                  <h4
                      id="cgu-obligations-utilisateur"
                      className="title--l3"
                  >
                      Obligations de l’Utilisateur
                  </h4>

                  <p>
                      Sans préjudice des autres obligations prévues aux présentes, l’Utilisateur s’engage à respecter les obligations qui suivent&nbsp;:
                  </p>

                  <ol className="list--l2">
                      <li>
                          <p>
                              L’Utilisateur s’engage, dans son usage des <i>Services</i>, à respecter les lois et règlements en vigueur et à ne pas porter atteinte aux droits de tiers ou à l’ordre public.
                          </p>
                          <p>
                              Il est notamment seul responsable du bon accomplissement de toutes les formalités notamment administratives, fiscales et/ou sociales et de tous les paiements de cotisations, taxes ou impôts de toutes natures qui lui incombent, le cas échéant, en relation avec son utilisation des <i>Services</i>. La responsabilité de <i className="text-nowrap">Hack the Radio</i> ne pourra en aucun cas être engagée à ce titre.
                          </p>
                      </li>
                      <li>
                          L’Utilisateur reconnaît avoir pris connaissance sur le site des caractéristiques et contraintes, notamment techniques, de l’ensemble des <i>Services</i>. Il est seul responsable de son utilisation des <i>Services</i>.
                      </li>
                      <li>
                          L’Utilisateur est informé et accepte que la mise en œuvre des <i>Services</i> nécessite qu’il soit connecté à internet et que la qualité des <i>Services</i> dépend directement de cette connexion, dont il est seul responsable.
                      </li>
                      <li>
                          L’Utilisateur s’engage à faire un usage strictement personnel des <i>Services</i>. Il s’interdit en conséquence de céder, concéder ou transférer tout ou partie de ses droits ou obligations au titre des présentes à un tiers, de quelque manière que ce soit.
                      </li>
                      <li>
                          L’Utilisateur s’engage à fournir à <i className="text-nowrap">Hack the Radio</i> toutes les informations nécessaires à la bonne exécution des <i>Services</i>. Plus généralement, l’Utilisateur s’engage à coopérer activement avec <i className="text-nowrap">Hack the Radio</i> en vue de la bonne exécution des présentes.
                      </li>
                      <li>
                          L’Utilisateur reconnaît que les <i>Services</i> lui offrent une solution supplémentaire mais non alternative des moyens qu’il utilise déjà par ailleurs pour atteindre le même objectif et que cette solution ne saurait se substituer à ces autres moyens.
                      </li>
                  </ol>

                  <h4
                      id="cgu-garantie-utilisateur"
                      className="title--l3"
                  >
                      Garantie de l’Utilisateur
                  </h4>

                  <p>
                      L’Utilisateur garantit <i className="text-nowrap">Hack the Radio</i> contre toutes plaintes, réclamations, actions et/ou revendications quelconques que <i className="text-nowrap">Hack the Radio</i> pourrait subir du fait de la violation, par l’Utilisateur d’une quelconque de ses obligations ou garanties aux termes des présentes conditions générales.
                  </p>
                  <p>
                      Il s’engage à indemniser <i className="text-nowrap">Hack the Radio</i> de tout préjudice qu’elle subirait et à lui payer tous les frais, charges et/ou condamnations qu’elle pourrait avoir à supporter de ce fait.
                  </p>

                  <h4
                      id="cgu-comportememts-prohibes"
                      className="title--l3"
                  >
                      Comportements prohibés
                  </h4>

                  <ol className="list--l2">
                      <li>
                          Il est strictement interdit d’utiliser les <i>Services</i> aux fins suivantes&nbsp;:

                          <ul className="list--roman">
                              <li>
                                  l’exercice d’activités illégales, frauduleuses ou portant atteinte aux droits ou à la sécurité des tiers,
                              </li>
                              <li>
                                  l’atteinte à l’ordre public ou la violation des lois et règlements en vigueur,
                              </li>
                              <li>
                                  l’intrusion dans le système informatique d’un tiers ou toute activité de nature à nuire, contrôler, interférer, ou intercepter tout ou partie du système informatique d’un tiers, en violer l’intégrité ou la sécurité,
                              </li>
                              <li>
                                  l’envoi d’emails non sollicités et/ou de prospection ou sollicitation commerciale,
                              </li>
                              <li>
                                  les manipulations destinées à améliorer le référencement d’un site tiers,
                              </li>
                              <li>
                                  l’aide ou l’incitation, sous quelque forme et de quelque manière que ce soit, à un ou plusieurs des actes et activités décrits ci-dessus,
                              </li>
                              <li>
                                  et plus généralement toute pratique détournant les <i>Services</i> à des fins autres que celles pour lesquelles ils ont été conçus.
                              </li>
                          </ul>
                      </li>
                      <li>
                          Il est strictement interdit aux Utilisateurs de copier et/ou de détourner à leurs fins ou à celles de tiers le concept, les technologies ou tout autre élément du site de <i className="text-nowrap">Hack the Radio</i>
                      </li>
                      <li>
                          Sont également strictement interdits&nbsp;:

                          <ol className="list--roman">
                              <li>
                                  tous comportements de nature à interrompre, suspendre, ralentir ou empêcher la continuité des <i>Services</i>,
                              </li>
                              <li>
                                  toutes intrusions ou tentatives d’intrusions dans les systèmes de <i className="text-nowrap">Hack the Radio</i>,
                              </li>
                              <li>
                                  tous détournements des ressources système du site,
                              </li>
                              <li>
                                  toutes actions de nature à imposer une charge disproportionnée sur les infrastructures de ce dernier,
                              </li>
                              <li>
                                  toutes atteintes aux mesures de sécurité et d’authentification,
                              </li>
                              <li>
                                  tous actes de nature à porter atteinte aux droits et intérêts financiers, commerciaux ou moraux de <i className="text-nowrap">Hack the Radio</i> ou des usagers de son site, et enfin plus généralement
                              </li>
                              <li>
                                  tout manquement aux présentes conditions générales.
                              </li>
                          </ol>
                      </li>
                      <li>
                          Il est strictement interdit de monnayer, vendre ou concéder tout ou partie de l’accès aux <i>Services</i> ou au site, ainsi qu’aux informations qui y sont hébergées et/ou partagées.
                      </li>
                  </ol>

                  <h4
                      id="cgu-sanctions-des-manquements"
                      className="title--l3"
                  >
                      Sanctions des manquements
                  </h4>

                  <p>
                      En cas de manquement à une quelconque des dispositions des présentes conditions générales ou plus généralement, d’infraction aux lois et règlements en vigueur par un Utilisateur, <i className="text-nowrap">Hack the Radio</i> se réserve le droit de prendre toute mesure appropriée et notamment de&nbsp;:
                  </p>

                  <ol className="list--roman">
                      <li>
                          suspendre ou résilier l’accès aux <i>Services</i> de l’Utilisateur, auteur du manquement ou de l’infraction, ou y ayant participé,
                      </li>
                      <li>
                          publier sur le site tout message d’information que <i className="text-nowrap">Hack the Radio</i> jugera utile,
                      </li>
                      <li>
                          avertir toute autorité concernée,
                      </li>
                      <li>
                          engager toute action judiciaire.
                      </li>
                  </ol>

                  <h4
                      id="cgu-responsabilite-et-garantie"
                      className="title--l3"
                  >
                      Responsabilité et garantie de <i className="text-nowrap">Hack the Radio</i>
                  </h4>

                  <ol className="list--l2">
                      <li>
                          <i className="text-nowrap">Hack the Radio</i> s’engage à fournir les <i>Services</i> avec diligence et selon les règles de l’art, étant précisé qu’il pèse sur elle une obligation de moyens, à l’exclusion de toute obligation de résultat, ce que les Utilisateurs reconnaissent et acceptent expressément.

                          Les Contenus préjudiciables à un tiers peuvent faire l’objet d’une notification à <i className="text-nowrap">Hack the Radio</i> selon les modalités prévues par l’article 6 I 5 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, <i className="text-nowrap">Hack the Radio</i> se réservant de prendre les mesures décrites à l’<a href="#cgu-sanctions-des-manquements">article 12</a>.
                      </li>
                      <li>
                          <i className="text-nowrap">Hack the Radio</i> décline toute responsabilité en cas de perte éventuelle des informations accessibles dans l’<i>Espace Personnel</i> de l’Utilisateur, celui-ci devant en sauvegarder une copie et ne pouvant prétendre à aucun dédommagement à ce titre.
                      </li>
                      <li>
                          <i className="text-nowrap">Hack the Radio</i> s’engage à procéder régulièrement à des contrôles afin de vérifier le fonctionnement et l’accessibilité du site. À ce titre, <i className="text-nowrap">Hack the Radio</i> se réserve la faculté d’interrompre momentanément l’accès au site pour des raisons de maintenance. De même, <i className="text-nowrap">Hack the Radio</i> ne saurait être tenue responsable des difficultés ou impossibilités momentanées d’accès au site qui auraient pour origine des circonstances qui lui sont extérieures, la force majeure, ou encore qui seraient dues à des perturbations des réseaux de télécommunication.
                      </li>
                      <li>
                          <i className="text-nowrap">Hack the Radio</i> ne garantit pas aux Utilisateurs

                          <ol className="list--roman">
                              <li>
                                  que les <i>Services</i>, soumis à une recherche constante pour en améliorer notamment la performance et le progrès, seront totalement exempts d’erreurs, de vices ou défauts,
                              </li>
                              <li>
                                  que les <i>Services</i>, étant standard et nullement proposés à la seule intention d’un Utilisateur donné en fonction de ses propres contraintes personnelles, répondront spécifiquement à ses besoins et attentes.
                              </li>
                          </ol>
                      </li>
                      <li>
                          En tout état de cause, la responsabilité susceptible d’être encourue par <i className="text-nowrap">Hack the Radio</i> au titre des présentes est expressément limitée aux seuls dommages directs avérés subis par l’Utilisateur.
                      </li>
                  </ol>

                  <h4
                      id="cgu-propriete-intellectuelle"
                      className="title--l3"
                  >
                      Propriété intellectuelle
                  </h4>

                  <p>
                      Les systèmes, logiciels, structures, infrastructures, bases de données et contenus de toute nature (textes, images, visuels, musiques, logos, marques, base de données, etc.) exploités par <i className="text-nowrap">Hack the Radio</i> au sein du site sont protégés par tous droits de propriété intellectuelle ou droits des producteurs de bases de données en vigueur. Tous désassemblages, décompilations, décryptages, extractions, réutilisations, copies et plus généralement, tous actes de reproduction, représentation, diffusion et utilisation d’un quelconque de ces éléments, en tout ou partie, sans autorisation sont strictement interdits et pourront faire l’objet de poursuites judiciaires.
                  </p>

                  <h4
                      id="cgu-donnees-a-caractere-personnel"
                      className="title--l3"
                  >
                      Données à caractère personnel
                  </h4>

                  <p>
                      <i className="text-nowrap">Hack the Radio</i> pratique une politique de protection des données personnelles dont les caractéristiques sont explicitées dans le document intitulé «&nbsp;Charte de confidentialité&nbsp;», dont l’Utilisateur est expressément invité à prendre connaissance sur le site.
                  </p>

                  <h4
                      id="cgu-liens-et-sites-tiers"
                      className="title--l3"
                  >
                      Liens et sites tiers
                  </h4>

                  <p>
                      <i className="text-nowrap">Hack the Radio</i> ne pourra en aucun cas être tenue pour responsable de la disponibilité technique de sites internet ou d’applications mobiles exploités par des tiers (y compris ses éventuels partenaires) auxquels l’Utilisateur accéderait par l'intermédiaire du site.
                  </p>
                  <p>
                      <i className="text-nowrap">Hack the Radio</i> n'endosse aucune responsabilité au titre des contenus, publicités, produits et/ou services disponibles sur de tels sites et applications mobiles tiers dont il est rappelé qu’ils sont régis par leurs propres conditions d’utilisation.
                  </p>
                  <p>
                      <i className="text-nowrap">Hack the Radio</i> n'est pas non plus responsable des transactions intervenues entre l’Utilisateur et un quelconque annonceur, professionnel ou commerçant (y compris ses éventuels partenaires) vers lequel l’Utilisateur serait orienté par l'intermédiaire du site et ne saurait en aucun cas être partie à quelques litiges éventuels que ce soit avec ces tiers concernant notamment la livraison de produits et/ou services, les garanties, déclarations et autres obligations quelconques auxquelles ces tiers sont tenus.
                  </p>

                  <h4
                      id="cgu-duree-des-services-desinscription"
                      className="title--l3"
                  >
                      Durée des <i>Services</i>, désinscription
                  </h4>

                  <p>
                      Les <i>Services</i> sont souscrits pour une durée indéterminée.
                  </p>
                  <p>
                      L’Utilisateur peut se désinscrire des <i>Services</i> à tout moment, en adressant une demande à cet effet à <i className="text-nowrap">Hack the Radio</i> par email, aux coordonnées mentionnées à l’<a href="#cgu-exploitant-des-services">article 2</a>.
                  </p>
                  <p>
                      La désinscription est effective immédiatement. Elle entraîne la suppression automatique du <i>Compte</i> de l’Utilisateur.
                  </p>

                  <h4
                      id="cgu-modifications"
                      className="title--l3"
                  >
                      Modifications
                  </h4>

                  <p>
                      <i className="text-nowrap">Hack the Radio</i> se réserve la faculté de modifier à tout moment les présentes conditions générales.
                  </p>
                  <p>
                      L’Utilisateur sera informé de ces modifications par tout moyen utile.
                  </p>
                  <p>
                      L’Utilisateur qui n’accepte pas les conditions générales modifiées doit se désinscrire des <i>Services</i> selon les modalités prévues à l’<a href="#cgu-modifications">article 18</a>.
                  </p>
                  <p>
                      Tout Utilisateur qui a recours aux <i>Services</i> postérieurement à l’entrée en vigueur des conditions générales modifiées est réputé avoir accepté ces modifications.
                  </p>

                  <h4
                      id="cgu-langue"
                      className="title--l3"
                  >
                      Langue
                  </h4>

                  <p>
                      Dans l’hypothèse d’une traduction des présentes conditions générales dans une ou plusieurs langues, la langue d’interprétation sera la langue française en cas de contradiction ou de contestation sur la signification d’un terme ou d’une disposition.
                  </p>

                  <h4
                      id="cgu-loi-applicable-et-juridiction"
                      className="title--l3"
                  >
                      Loi applicable et juridiction
                  </h4>

                  <p>
                      Les présentes conditions générales sont régies par la loi française.
                  </p>
                  <p>
                      En cas de contestation sur la validité, l’interprétation et/ou l’exécution des présentes conditions générales, les parties conviennent que les tribunaux de Paris seront exclusivement compétents pour en juger, sauf règles de procédure impératives contraires.
                  </p>

                  <h4
                      id="cgu-entree-en-vigueur"
                      className="title--l3"
                  >
                      Entrée en vigueur
                  </h4>

                  <p>
                      Les présentes conditions générales sont entrées en vigueur le 16 octobre 2018.
                  </p>
              </div>
      </main>
    );
  }
}
