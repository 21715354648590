import {IMPORT_SERIE} from '../types'
import axios from 'axios';
import URL from '../../helpers/helper_url'

export default function importSerie(user_id, serie_id) {
  console.log("Import serie")
  const url = URL + `/series/${serie_id}`;
  const request = axios.get(url, {params: {user_id: user_id}});

  return {
    type: IMPORT_SERIE,
    payload: request
  };
};
