import {ENDED} from './types';
import axios from 'axios';
import URL from '../helpers/helper_url'

export default function ended(user_id,section_id, position, action_origin) {

  const url = URL + `/user_actions/create`;
  var params = {
    user_id: user_id,
    section_id: section_id,
    action_type: "ended",
    executed_at: position,
    action_device: action_origin
  }
  const request = axios.post(url, params);

  return {
    type: ENDED,
    payload: section_id
  };
};
