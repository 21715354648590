import {IMPORT_HOME_DISCONNECTED_PLAYLISTS} from '../types';
import axios from 'axios';
import URL from '../../helpers/helper_url'

export default function importHomeDisconnectedPlaylists() {
  console.log("Import home disconnected playlists")
  const url = URL + `/home_disconnected_playlists`;
  const request = axios.get(url);

  return {
    type: IMPORT_HOME_DISCONNECTED_PLAYLISTS,
    payload: request
  };
};
