import { combineReducers } from 'redux';
import ActiveUserReducer from './reducer_active_user';
import IsPlayingReducer from './reducer_is_playing';
import IsLoadingReducer from './reducer_is_loading';
import FirstSoundReducer from './reducer_first_sound'
import SectionsReducer from './reducer_sections';
import RelatedSectionsReducer from './reducer_related_sections';
import RelatedPlaylistsReducer from './reducer_related_playlists';
import PlaylistsReducer from './reducer_playlists'
import SeriesReducer from './reducer_series';
import CollectionsReducer from './reducer_collections';
import HistoriqueReducer from './reducer_historique'
import SelectedSectionReducer from './reducer_selected_section';
import PreviousSectionsReducer from './reducer_previous_sections';
import NextSectionsReducer from './reducer_next_sections';
import LectureListReducer from './reducer_lecture_list';
import ShowInfoReducer from './reducer_show_info';
import PlayerPositionReducer from './reducer_player_position';
import IncreaseActiveSectionsPageNrReducer from './reducer_increase_active_sections_page_nr';
import IsFetchingSectionsReducer from './reducer_is_fetching_sections';
import HasMoreSectionsReducer from './reducer_has_more_sections';

const rootReducer = combineReducers({
  // L'id du user connecté
  active_user: ActiveUserReducer,

  // Le player joue un son ?
  is_playing: IsPlayingReducer,

  // Le player charge un son ?
  is_loading: IsLoadingReducer,

  // Premier son joué ou non, permet de bloquer le comportement par défaut des navigateurs
  first_sound: FirstSoundReducer,

  // La liste des sections de la page (array contenant la liste des sons + infos associées (start, end, last_position, titre, description...))
  sections: SectionsReducer,

  // La liste des sections liées à une section (uniquement pour le show)
  related_sections: RelatedSectionsReducer,

  // La liste des playlists liées à une section (uniquement pour le show)
  related_playlists: RelatedPlaylistsReducer,

  // La liste des playlists de la page (array contenant la liste des playlists + sections associées + infos associées (start, end, last_position, titre, description...))
  playlists: PlaylistsReducer,

  // La liste des séries de la page (idem playlist)
  series: SeriesReducer,

  // La liste des collections de la page (idem collection)
  collections: CollectionsReducer,

  // La liste des dernière sections écoutées (ne pas confondre avec previous_sections)
  historique: HistoriqueReducer,

  // Les données de la section en cours de lecture
  selected_section: SelectedSectionReducer,

  // Array contenant les données des sons écoutés au cours de la session en cours
  previous_sections: PreviousSectionsReducer,

  // Array contenant les données des sons à écouter dans la session en cours
  next_sections: NextSectionsReducer,

  // Les sons de la liste de lecture (si écoutable sur chaque page)
  lecture_list: LectureListReducer,

  // Affichage des infos du son dans le player
  show_info: ShowInfoReducer,

  // La position du player (nécessaire pour le slider)
  player_position: PlayerPositionReducer,

  // InfiniteScroll page increasing
  active_sections_page_nr: IncreaseActiveSectionsPageNrReducer,
  is_fetching_sections: IsFetchingSectionsReducer,
  has_more_sections: HasMoreSectionsReducer

});

export default rootReducer;
