import {CHANGE_SOUND, PLAY_PAUSE} from '../actions/types'

export default function(state=true, action){
  switch (action.type) {
    case PLAY_PAUSE :
      if (action.error === true) {
        return state;
      } else {
        return false;
      }
    case CHANGE_SOUND :
      if (action.error === true) {
        return state;
      } else {
        return false;
      }
  }
  return state;
}
