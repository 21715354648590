import {ADD_LIST} from './types';
import axios from 'axios';
import URL from '../helpers/helper_url'

export default function addList(user_id, type, item_id) {
  const url = URL + `/plays/add_to_list`;
  var params = {
    user_id: user_id,
    type: type,
    id: item_id,
  }
  const request = axios.post(url, params);
  return {
    type: ADD_LIST,
    payload: request
  };
};
