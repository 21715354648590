import { HAS_MORE_SECTIONS } from '../actions/types';

export default function(state=true, action){
  switch (action.type) {
    case HAS_MORE_SECTIONS :
      if (action.error === true) {
        return false;
      } else {
        return action.payload
      }
  }
  return state;
}
