import React, { Component } from 'react';

export default class Credits extends Component {
  // constructor(props) {
  //   super(props);
  // }

  renderHTML(value) {
    return ( { __html: value} )
  }

  render() {
    return (
      <>
        <div className="card_title_informations">
          <div className="title__holder">
            <img
              className="card__cover"
              src={this.props.photo}
              width="120"
              height="120"
              alt=""/>
          </div>
        </div>
        <div className="card_body__informations">
          <ul className="media-body list-unstyled">
            <li
              className="metas__author"
              dangerouslySetInnerHTML={this.renderHTML(this.props.footer)}>
            </li>
          </ul>
        </div>
      </>
    )
  }
}
