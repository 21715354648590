import React, { Component } from 'react';

export default class Podcast extends Component {

  render() {
    return (
      <main className="page--static" role="main">
        <div className="headband e-card card--title-middle card--plain card--title-play container-fluid ">
          <div className="row">
            <div className="headband__img card__title col-12 col-md-5 col-lg-4">
              <div className="title__holder">
                <h2 className="title__text">
                  Proposer un son magnétique
                </h2>
              </div>
            </div>
            <div className="headband__info col-12 col-md-7 col-lg-8 d-flex flex-column justify-content-between">
              <div className="headband__metas row align-items-baseline"></div>
              <div className="headband__description p-3 p-lg-5 row">
                <div className="col p-0">
                  <p>Auditeurs comme producteurs de podcasts peuvent proposer leurs coups de coeur et leurs podcasts.</p>
                  <p>N'hésitez pas à nous faire découvrir vos sons magnétiques</p>
                </div>
              </div>
              <div className="headband__actions actions row"></div>
            </div>
          </div>
        </div>
        <div className="contents container">
          <p>
            <strong>Aujourd’hui, un panel de podcasts et une offre éditoriale de recommandations de sons.</strong>
            Nous vous demandons de nous présenter le podcast, mais également de choisir un «&nbsp;épisode&nbsp;» ou un son en particulier que nous pourrons proposer dans les «&nbsp;crispy sons&nbsp;» et les playlists de podcasts.
          </p>
          <p>
            <strong>Demain, un agrégateur complet. </strong>
            Nous développons un agrégateur de podcasts qui accueillera un outil de recherche de podcasts dans quelques mois, pour que tous les auditeurs puissent trouver les podcasts qu’ils écoutent.
          </p>
          <p>
            <a className="typeform-share button" href="https://hacktheradio.typeform.com/to/Hfzizn" data-mode="popup" target="_blank" >
              Proposer un podcast
            </a>
          </p>
          <p>
            Chaque mois, nous répondons par mail aux personnes qui proposent des sons. Si nous n’intégrons pas votre proposition dans les semaines  qui suivent, cela ne veut pas dire que cela ne sera pas le cas par la suite, ou qu’il n’est pas «&nbsp;intégré&nbsp;» dans notre base, pour être proposé plus tard.
          </p>
          <p>
            <strong>Elson est un service en évolution permanente. </strong>
            Nos sélections de recommandations évoluent à mesure que nous intégrons de nouvelles fonctionnalités, afin de proposer une expérience d’écoute inédite à une audience grand public (Exemple&nbsp;: nous n’intégrions pas de série durant la phase alpha du projet, c’est désormais le cas. Nous intégrerons les sons fortement liés à une actualité dans quelques semaines, avec de nouvelles fonctionnalités).
          </p>
        </div>
      </main>

    );
  }
}
