import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import importPlaylist from '../../actions/pages/import_playlist'

import PlayButton from '../play_button';
import Headband from '../headband';
import AddListButton from '../add_list_button';
import CardSectionPlaylist from '../../components/card_section_playlist';


class Playlist extends Component {
  constructor(props) {
    super(props);
    let playlist_array = this.props.playlists.filter(item => item.playlist.id == this.props.match.params.id)
    if (_.isEmpty(playlist_array)) {
      this.props.importPlaylist(this.props.active_user, this.props.match.params.id)
    }
  }

  renderHTML(value) {
    return ( { __html: value} )
  }

  calcRemainingTime(item) {
    const remaining_time = (item.last_user_action.executed_at / item.section.duration)*100
    return `${remaining_time}%`
  }

  render() {
    let sections, playlist, sections_number, duration=0;
    const { playlists } = this.props;
    const { id } = this.props.match.params;
    if (!_.isEmpty(playlists.filter(item => item.playlist.id == id))) {
      const playlistObj =  playlists.filter(item => item.playlist.id == id)[0]
      playlist = playlistObj.playlist
      sections_number = playlistObj.sections.length

      duration = _.reduce(
        playlistObj.sections,
        (duration,item) => duration += item.section.duration
      );

      sections = playlistObj.sections.map(item =>
        <CardSectionPlaylist
          playlist_id={playlist.id}
          section={item}
          key={item.section.id}
        />
      )
    } else {
      playlist = {}
      duration = 0
    }
    return (
      <main className="page--playlist" role="main">
        <Headband type="playlist" id={id} />
        <div className="contents container">
          <section className="contents__type">
            <div className="contents__deck">
              {sections}
            </div>
          </section>
        </div>
      </main>
    );
  }
}
function mapsStateToProps(state) {
  return {
    active_user: state.active_user,
    selected_section: state.selected_section,
    next_sections: state.next_sections,
    sections: state.sections,
    is_playing: state.is_playing,
    first_sound: state.first_sound,
    playlists: state.playlists
  };
}
function mapDispatchToProps(dispatch){
  return bindActionCreators({importPlaylist}, dispatch);
}
export default connect(mapsStateToProps, mapDispatchToProps, null)(Playlist);
