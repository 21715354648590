import {CHANGE_LIST_ORDER} from './types';
import axios from 'axios';
import URL from '../helpers/helper_url'

export default function changeListOrder(user_id, liste) {
  const url = URL + `/plays/change_list_order`;
  var params = {
    user_id: user_id,
    liste: liste,
  }
  const request = axios.post(url, params);
  return {
    type: CHANGE_LIST_ORDER,
    payload: request
  };
};
