import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {isBrowser, isMobileOnly, isTablet} from "react-device-detect";

import removeList from '../actions/remove_list'

class RemoveListButton extends Component {

  render() {
    return (
      <button onClick={() => this.props.removeList(this.props.active_user, this.props.origin, this.props.id)} className="col-1 col-lg-1 order-6 order-sm-6 order-md-4 order-lg-8 mt-2 mt-sm-0 text-center text-sm-right" data-action="remove">
        ×
      </button>
    )
  }
}

function mapsStateToProps(state) {
  return {
    active_user: state.active_user,
  };
}
function mapDispatchToProps(dispatch){
  return bindActionCreators({removeList}, dispatch);
}
export default connect(mapsStateToProps, mapDispatchToProps, null)(RemoveListButton);
