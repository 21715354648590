import {IMPORT_SECTION} from '../types';
import axios from 'axios';
import URL from '../../helpers/helper_url'

export default function importSection(user_id, section_id) {
  console.log("Import section")
  const url = URL + `/sections/${section_id}`;
  const request = axios.get(url, {params: {user_id: user_id}});

  return {
    type: IMPORT_SECTION,
    payload: request
  };
};
