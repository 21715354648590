import {
  IMPORT_HOME_PLAYLISTS,
  IMPORT_HOME_DISCONNECTED_PLAYLISTS,
  IMPORT_PLAYLISTS,
  IMPORT_PLAYLIST,
  IMPORT_SECTION,
  IMPORT_SERIES,
  IMPORT_SERIE,
  SET_SPEED,
  CHANGE_SOUND
} from '../actions/types'
// import {IMPORT_COLLECTIONS} from '../actions/pages/import_collections'
// import {IMPORT_COLLECTION} from '../actions/pages/import_collection'
// import {IMPORT_HISTORIQUE} from '../actions/pages/import_historique'
// import {IMPORT_LISTE} from '../actions/pages/import_liste'

import _ from 'lodash';

export default function(state=[], action){
  switch (action.type) {
    case IMPORT_HOME_PLAYLISTS :
    case IMPORT_HOME_DISCONNECTED_PLAYLISTS :
    case IMPORT_PLAYLISTS :
    case IMPORT_PLAYLIST :
      if (action.error === true) {
        return state;
      } else {
        return action.payload.data.playlists;
      }
    case CHANGE_SOUND :
      var new_state = []
      state.map(item =>
        new_state.push({
          playlist: item.playlist,
          sections: item.sections.map((section) => section.section.id === action.payload.previous_sound.section.id ? {...section, last_user_action : {executed_at: action.payload.position} } : section),
          presentation_section: item.presentation_section
        })
      )
      return new_state
    case SET_SPEED :
      var new_state = []
      state.map(item =>
        new_state.push({
          playlist: item.playlist,
          sections: item.sections.map((section) => section.section.id === action.payload.section ? {...section, player_speed : action.payload.player_speed } : section),
          presentation_section: item.presentation_section
        })
      )
      return new_state
    }
  return state;
}
