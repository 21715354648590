import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import playPause from '../actions/play_pause';
import changeSound from '../actions/change_sound';
import setPosition from '../actions/set_position';
import addPile from '../actions/add_pile';
import resetNextSections from '../actions/reset_next_sections';

class PileGestionButton extends Component {
  constructor(props) {
    super(props);
  }

  playListe() {
    const audio_html_dom = document.getElementById("audio-player");
    const position = audio_html_dom.currentTime;
    const action = this.props.is_playing ? "pause" : "play"
    if(this.props.is_playing){
      audio_html_dom.pause();
      this.props.playPause(
        this.props.active_user,
        this.props.selected_section.section.id,
        action,
        position,
        null,
        null);
    }
    this.props.resetNextSections()
    let list_sections = []
    this.props.lecture_list.map(item => {
      if (item.section)
        list_sections.push(item)
      else if (item.playlist)
        item.sections.map(section =>
          list_sections.push(section)
        )
      else if (item.serie)
        item.sections.map(section =>
          list_sections.push(section)
        )
    })
    list_sections.map((item, index) => {
      if (index === 0) {
        this.props.changeSound(item, this.props.selected_section, position)
        this.props.setPosition(0)
      } else {
        this.props.addPile(item)
      }
    })
  }

  render() {
    var visible = _.isEmpty(this.props.lecture_list) ? "d-none" : "list__play btn--orange"
    return (
      <div className="player__list col-sm-1 col-2 d-flex flex-column align-items-center justify-content-center">
        <button onClick={() => this.playListe()} className={`${visible}`} data-action="listPlay">
          <svg y="0" x="0" viewBox="0 0 58 58" width="42" height="42">
            <circle r="29" cy="29" cx="29" />
            <g transform="matrix(1.553719,0,0,1.5523791,12.324854,11.09736)" id="lines">
              <line className="cls-1" y2="17.996109" x2="0.99999982" y1="17.996109" x1="14" />
              <line className="cls-1" y2="13" x2="1" y1="13" x1="14" />
              <line className="cls-1" y2="8" x2="1" y1="8" x1="18" />
              <line className="cls-1" y2="3" x2="1" y1="3" x1="18" />
              <polygon transform="translate(0.93054285,0.53417385)" points="17,12 17,23 23,17 " />
            </g>
          </svg>
        </button>
      </div>
    );
  }
}

function mapsStateToProps(state) {
  return {
    active_user: state.active_user,
    lecture_list: state.lecture_list,
    is_playing: state.is_playing,
    selected_section: state.selected_section
  };
}
function mapDispatchToProps(dispatch){
  return bindActionCreators({playPause, changeSound, setPosition, addPile, resetNextSections}, dispatch);
}
export default connect(mapsStateToProps, mapDispatchToProps, null)(PileGestionButton);
