import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';


import playPause from '../actions/play_pause'
import changeSound from '../actions/change_sound'


class NextButton extends Component {
  constructor(props) {
    super(props);
    this.handleNextSound = this.handleNextSound.bind(this);
  }

  handleNextSound() {
    if (_.isEmpty(this.props.next_sections)) {
      return
    }
    const audio_html_dom = document.getElementById("audio-player");
    audio_html_dom.pause()
    const action = this.props.is_playing ? "pause" : "play"
    const position = audio_html_dom.currentTime
    if (this.props.is_playing){
      this.props.playPause(
        this.props.active_user,
        this.props.selected_section.section.id,
        action,
        position,
        null,
        null);
    }
    const next_sound = this.props.next_sections[0];
    this.props.changeSound(
      next_sound,
      this.props.selected_section,
      position);
  }

  render() {
    let classname = _.isEmpty(this.props.next_sections) ? "d-none" : ""
    return (
      <button
        onClick={() => this.handleNextSound()}
        className={`controls__btn px-1 px-sm-2 ${classname}`}
        data-action="next"
      >
        <span className="sr-only">next</span>
        <svg className="with-bg" height="42" width="42" x="0" y="0" viewBox="0 0 100 100">
          <path d="M50,5C25.1,5,5,25.1,5,50c0,24.9,20.1,45,45,45s45-20.1,45-45C95,25.1,74.9,5,50,5z M68.8,65.9c0,1.7-1.4,3-3,3h-0.4   c-1.6,0-3-1.3-3-3V53.1L35.7,68.5c-2,1.2-4.5-0.3-4.5-2.6V34.1c0-2.3,2.5-3.8,4.5-2.6l26.7,15.4V34.1c0-1.6,1.4-3,3-3h0.4   c1.6,0,3,1.4,3,3V65.9z" />
        </svg>
      </button>
    );
  }
}

function mapsStateToProps(state) {
  return {
    active_user: state.active_user,
    selected_section: state.selected_section,
    next_sections: state.next_sections,
    first_sound: state.first_sound,
    is_playing: state.is_playing
  };
}
function mapDispatchToProps(dispatch){
  return bindActionCreators({playPause,changeSound}, dispatch);
}
export default connect(mapsStateToProps, mapDispatchToProps, null)(NextButton);
