import {JUMP} from './types';
import axios from 'axios';
import URL from '../helpers/helper_url'

export default function jump(user_id, section_id ,jump_executed_at, skip_at_executed_at, action_device, action_origin) {
  const url = URL + `/user_actions/create_jump_skip_at`;
  var params = {
    user_id: user_id,
    section_id: section_id,
    jump_executed_at: jump_executed_at,
    skip_at_executed_at: skip_at_executed_at,
    action_device: action_device,
    action_origin: action_origin
  }
  const request = axios.post(url, params);

  return {
    type: JUMP,
    payload: section_id
  };
};
