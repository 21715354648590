import {IMPORT_LISTE} from '../types';
import axios from 'axios';
import URL from '../../helpers/helper_url'

export default function importListe(user_id) {
  console.log("Import List")
  const url = URL + `/my_list`;
  const request = axios.get(url, {params: {user_id: user_id}});

  return {
    type: IMPORT_LISTE,
    payload: request
  };
};
