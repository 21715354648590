import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import formatTime from '../helpers/helper_time';

class PlayerInfo extends Component {
  renderHTML(value) {
    return ( { __html: value} )
  }


  formatDate(publication_date) {
    const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    const date = new Date(publication_date);
    const month = months[date.getMonth()]
    const year  = date.getFullYear()
    return `${month} ${year} `
  }

  render() {
    let data;
    const visible = this.props.show_info ? "player__infos row collapse order-3 show": "d-none";
    if (!this.props.selected_section.section.footer) {
      data =
      <ul className="media-body list-unstyled">
        <li>{this.props.selected_section.section.title_website}</li>
        <li>Date : {this.formatDate(this.props.selected_section.section.publication_date)}</li>
        <li>Durée : {formatTime(this.props.selected_section.section.source_duration)}</li>
      </ul>
    }  else {
      data =
      <ul className="media-body list-unstyled">
        <li className="metas__author" dangerouslySetInnerHTML={this.renderHTML(this.props.selected_section.section.footer)}></li>
      </ul>
    }
    return (
      <div id="infos" className={visible}>
        <div className="infos__description col-12 col-lg offset-lg-1 my-sm-5 my-3 pr-lg-0" dangerouslySetInnerHTML={this.renderHTML(this.props.selected_section.section.description)}>
        </div>
        <div className="infos__card col-12 col-lg m-lg-5 pl-lg-5 media align-self-start">
          <img className="card__cover mr-4 mr-lg-5" src={this.props.selected_section.section.photo_source} width="80" height="80" alt=""/>
          {data}
        </div>
      </div>
    );
  }
}

function mapsStateToProps(state) {
  return {
    selected_section: state.selected_section,
    show_info: state.show_info
  };
}

export default connect(mapsStateToProps, null, null)(PlayerInfo);
