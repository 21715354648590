// import {IMPORT_COLLECTIONS} from '../actions/pages/import_collections'
// import {IMPORT_COLLECTION} from '../actions/pages/import_collection'
import {
  IMPORT_LISTE,
  IMPORT_PLAYLISTS,
  IMPORT_PLAYLIST,
  IMPORT_SECTION,
  IMPORT_SERIES,
  IMPORT_SERIE,
  SET_SPEED,
  IMPORT_HOME_HISTORIQUE,
  CHANGE_LIST_ORDER,
  CHANGE_SOUND,
  ENDED,
  REMOVE_LIST,
  ADD_LIST
} from '../actions/types';

export default function(state=[], action){
  switch (action.type) {
    case IMPORT_LISTE :
    case IMPORT_SECTION :
    case IMPORT_PLAYLISTS :
    case IMPORT_PLAYLIST :
    case IMPORT_SERIES :
    case IMPORT_SERIE :
    case IMPORT_HOME_HISTORIQUE :
    case CHANGE_LIST_ORDER :
    // case IMPORT_COLLECTIONS :
    // case IMPORT_COLLECTION :
    case REMOVE_LIST :
      if (action.error === true) {
        return state;
      } else {
        return action.payload.data.liste
      }
    case ADD_LIST :
      if (action.error === true) {
        return state;
      } else {
        if (action.payload.data.error) {
          return state
        } else {
          return [...state, action.payload.data]
        }
      }
    case ENDED :
      var new_state = []
      state.map(item => {
        item.section ? (item.section.id !== action.payload ? new_state.push(item) : "") : ""
        item.playlist ? item.sections.filter(section => section.section.id !== action.payload).length === item.sections.length ? new_state.push(item) : item.sections.filter(section => section.section.id !== action.payload).length === 0 ? "" : new_state.push({playlist: item.playlist, sections: item.sections.filter(section => section.section.id !== action.payload)}) : ""
        item.serie ? item.sections.filter(section => section.section.id !== action.payload).length === item.sections.length ? new_state.push(item) : item.sections.filter(section => section.section.id !== action.payload).length === 0 ? "" : new_state.push({playlist: item.playlist, sections: item.sections.filter(section => section.section.id !== action.payload)}) : ""
      })
      return new_state
    case CHANGE_SOUND :
      var new_state = []
      state.map(item => {
        item.section ? new_state.push(item.section.id === action.payload.previous_sound.section.id ? {...item, last_user_action : {executed_at: action.payload.position} } : item) : ""
        item.playlist ? (new_state.push({playlist: item.playlist, sections: item.sections.map((section) => section.section.id === action.payload.previous_sound.section.id ? {...section, last_user_action : {executed_at: action.payload.position}} : section)})) : ""
        item.serie ? (new_state.push({ serie: item.serie, sections: item.sections.map((section) => section.section.id === action.payload.previous_sound.section.id ? {...section, last_user_action : {executed_at: action.payload.position} } : section)})) : ""
      })
      return new_state
    case SET_SPEED :
      var new_state = []
      state.map(item => {
        item.section ? new_state.push(item.section.id === action.payload.section ? {...item, player_speed : action.payload.player_speed } : item) : ""
        item.playlist ? (new_state.push({playlist: item.playlist, sections: item.sections.map( (section) => section.section.id === action.payload.section ? {...section, player_speed : action.payload.player_speed } : section)})) : ""
        item.serie ? (new_state.push({ serie: item.serie, sections: item.sections.map((section) => section.section.id === action.payload.section ? {...section, player_speed : action.payload.player_speed } : section)})) : ""
      })

      return new_state
  }
  return state;
}
