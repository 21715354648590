import {IMPORT_HOME_DISCONNECTED_SECTIONS} from '../types';
import axios from 'axios';
import URL from '../../helpers/helper_url'

export default function importHomeDisconnectedSections() {
  console.log("Import home disconnected sections")
  const url = URL + `/home_disconnected_sections`;
  const request = axios.get(url);

  return {
    type: IMPORT_HOME_DISCONNECTED_SECTIONS,
    payload: request
  };
};
