import {SET_SPEED} from './types';
import axios from 'axios';
import URL from '../helpers/helper_url'

export default function setSpeed(user_id, section_id, position, action_device, action_origin, player_speed) {
  const url = URL + `/user_actions/create`;
  var params = {
    user_id: user_id,
    section_id: section_id,
    action_type: "speed_update",
    executed_at: position,
    action_device: action_origin,
    detail: player_speed
  }
  const request = axios.post(url, params);

  return {
    type: SET_SPEED,
    payload: {player_speed: player_speed, section: section_id}
  };
};
