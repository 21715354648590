import {
  IMPORT_HOME_SECTIONS,
  IMPORT_PLAYLISTS,
  IMPORT_PLAYLIST,
  IMPORT_SECTIONS_PAGE,
  IMPORT_SERIES,
  IMPORT_SERIE,
  SET_SPEED,
  CHANGE_SOUND,
  IMPORT_RELATED_SECTIONS
} from '../actions/types';
// import {IMPORT_SECTION} from '../actions/pages/import_section'
import {IMPORT_COLLECTIONS} from '../actions/pages/import_collections'
import {IMPORT_COLLECTION} from '../actions/pages/import_collection'


export default function(state=[], action){
  switch (action.type) {
    case IMPORT_HOME_SECTIONS :
    case IMPORT_SECTIONS_PAGE :
    case IMPORT_PLAYLISTS :
    case IMPORT_PLAYLIST :
    case IMPORT_SERIES :
    case IMPORT_SERIE :
    case IMPORT_COLLECTIONS :
    case IMPORT_COLLECTION :
      if (action.error === true) {
        return state;
      } else {
        return []
      }
    case IMPORT_RELATED_SECTIONS :
      return action.payload.data.related_sections;
  }
  return state;
}
