import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {isBrowser, isMobileOnly, isTablet} from "react-device-detect";

import jump from '../actions/jump';

class SkipButton extends Component {

  handlePlayPause() {
    const audio_html_dom = document.getElementById("audio-player");
    const actual_position = audio_html_dom.currentTime
    let next_position = actual_position + this.props.value
    if (next_position < 0) {
      next_position = 0
    } else if (next_position > this.props.selected_section.section.end_at) {
      next_position = this.props.selected_section.section.end_at
    }
    audio_html_dom.currentTime = next_position
    this.props.jump(this.props.active_user, this.props.selected_section.section.id, actual_position, next_position, null, null)
  }

  render() {
    const hidemobile = this.props.hidemobile ? "d-none d-md-flex" : ""
    let button;
    if (this.props.type === "forward") {
      button = (<button onClick={() => this.handlePlayPause()} className={`controls__btn ${hidemobile} px-1 px-sm-2`} data-action={this.props.type} data-value={this.props.value}>
        <span className="control__text">
          <span className="sr-only">{this.props.type}</span>
            {this.props.value}
          <span className="sr-only">seconds</span>
        </span>
        <svg height="42" width="42" viewBox="0 0 12 12">
          <defs>
            <marker id={`forward-arrow-${this.props.value}`} className="svg-arc__arrow" refX=".5" refY="0" orient="auto">
              <path transform="scale(-0.25)" d="M 5.77,0 -2.88,5 V -5 Z" />
            </marker>
          </defs>
          <path id="control--skip" className="svg-arc" style={{markerStart: `url( #forward-arrow-${this.props.value})`}}  transform="translate(12,0) scale(-1,1)" d="M 2.7858085,1.6241367 A 5.2916665,5.2916665 0 0 1 9.310022,1.9803305 5.2916665,5.2916665 0 0 1 10.514749,8.4022357 5.2916665,5.2916665 0 0 1 4.5632293,11.098834 5.2916665,5.2916665 0 0 1 0.52931738,5.9588158"/>
        </svg>

      </button>);
    } else {
      button =(
        <button onClick={() => this.handlePlayPause()} className="controls__btn px-1 px-sm-2" data-action="rewind" data-value="5">
          <span className="control__text">
            <span className="sr-only">rewind</span>
              5
            <span className="sr-only">seconds</span>
          </span>
          <svg height="42" width="42" viewBox="0 0 12 12">
            <defs>
              <marker id="rewind-arrow" className="svg-arc__arrow" refX=".5" refY="0" orient="auto">
                <path transform="scale(-0.25)" d="M 5.77,0 -2.88,5 V -5 Z" />
              </marker>
            </defs>
            <path id="control--skip" className="svg-arc" style={{markerStart: "url( #rewind-arrow )"}} d="M 2.7858085,1.6241367 A 5.2916665,5.2916665 0 0 1 9.310022,1.9803305 5.2916665,5.2916665 0 0 1 10.514749,8.4022357 5.2916665,5.2916665 0 0 1 4.5632293,11.098834 5.2916665,5.2916665 0 0 1 0.52931738,5.9588158"/>
          </svg>
        </button>);
    }
    return (
      button
    )
  }
}

function mapsStateToProps(state) {
  return {
    active_user: state.active_user,
    selected_section: state.selected_section,
  };
}
function mapDispatchToProps(dispatch){
  return bindActionCreators({jump}, dispatch);
}
export default connect(mapsStateToProps, mapDispatchToProps, null)(SkipButton);
