import {PLAY_PAUSE} from './types';
import axios from 'axios';
import URL from '../helpers/helper_url'

export default function playPause(user_id, section_id, action_type, position, action_device, action_origin) {
  const url = URL + `/user_actions/create`;
  var params = {
    user_id: user_id,
    section_id: section_id,
    action_type: action_type,
    executed_at: position,
    action_device: action_origin
  }
  const request = axios.post(url, params);

  return {
    type: PLAY_PAUSE,
    payload: position
  };
};
