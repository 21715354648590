import {CHANGE_SOUND_STATUS, CHANGE_SOUND} from '../actions/types'

export default function(state=false, action){
  switch (action.type) {
    case CHANGE_SOUND :
      return true
    case CHANGE_SOUND_STATUS:
      return false;
  }
  return state;
}
