import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import changeSound from '../actions/change_sound';
import changeSoundStatus from '../actions/change_sound_status';
import playPause from '../actions/play_pause';
import ended from '../actions/ended';
import setPosition from '../actions/set_position';
import timeUpdate from '../actions/time_update';

// Le composant audio gère 2 actions. Passage au suivant à la fin d'un son + lancement automatique du son quand il a chargé.
// Il se met à jour uniquement si le son change (sinon coupure de son au play/pause)
// Premier lancement de son bloqué par la variable first_sound, crée à cet effet.

class AudioTag extends Component {
  state = { last_position_saved: 0 }

  shouldComponentUpdate(nextProps){
    return this.props.selected_section.section.id !== nextProps.selected_section.section.id
  }

  componentDidUpdate() {
    const audio_html_dom = document.getElementById("audio-player");
    audio_html_dom.load();
  }

  showError() {
    const audio_html_dom = document.getElementById("audio-player");
    audio_html_dom.load();
  }

  nextSound() {
    const audio_html_dom = document.getElementById("audio-player");
    audio_html_dom.pause()
    const position = audio_html_dom.currentTime
    audio_html_dom.currentTime = this.props.selected_section.section.start_at
    this.props.ended(
      this.props.active_user,
      this.props.selected_section.section.id,
      position,
      null);
    if (_.isEmpty(this.props.next_sections)) return;

    const next_sound = this.props.next_sections[0];
    this.props.changeSound(next_sound, this.props.selected_section, position)
  }

  playSong() {
    const audio_html_dom = document.getElementById("audio-player");
    audio_html_dom.playbackRate = this.props.selected_section.player_speed
    if (!this.props.first_sound){
      while (audio_html_dom.currentTime !== this.props.selected_section.last_user_action.executed_at && !this.props.is_playing) {
        audio_html_dom.currentTime = this.props.selected_section.last_user_action.executed_at
        this.props.setPosition(this.props.selected_section.last_user_action.executed_at)
        this.setState({last_position_saved: this.props.selected_section.last_user_action.executed_at})
        console.log("mise en position")
      }
      while (audio_html_dom.paused) {
        audio_html_dom.play()
        console.log("mise en lecture forcée")
      }
      this.props.playPause(
        this.props.active_user,
        this.props.selected_section.section.id,
        "play",
        this.props.selected_section.last_user_action.executed_at,
        null,
        null);
    }
  }

  checkPosition() {
    const audio_html_dom = document.getElementById("audio-player");
    audio_html_dom.playbackRate = this.props.selected_section.player_speed
    if (audio_html_dom.currentTime < this.props.selected_section.section.start_at) {
      audio_html_dom.currentTime = this.props.selected_section.section.start_at
    }
    if (audio_html_dom.currentTime > this.props.selected_section.section.end_at + 1) {
      audio_html_dom.currentTime = this.props.selected_section.section.start_at
    }
  }

  checkEndPosition() {
    const audio_html_dom = document.getElementById("audio-player");
    this.props.setPosition(audio_html_dom.currentTime)
    if (audio_html_dom.currentTime > this.props.selected_section.section.end_at) {
      audio_html_dom.pause()
      this.nextSound()
    }
    if (audio_html_dom.currentTime > this.state.last_position_saved + 20) {
      //envoyer la requete d'update
      this.props.timeUpdate(this.props.active_user, this.props.selected_section.section.id, "time_update", this.state.last_position_saved + 20, null, null)
      this.setState({last_position_saved: audio_html_dom.currentTime})
    }

  }

  render() {
    var source = this.props.selected_section ? this.props.selected_section.section.url : null
    return (
      <audio id="audio-player" className="d-none"
        onEnded={() => this.nextSound()}
        onError={() => this.showError()}
        onPlay={() => this.checkPosition()}
        onTimeUpdate={() => this.checkEndPosition()}
        onLoadedMetadata={() => this.playSong()}
        onCanPlayThrough={() => this.props.changeSoundStatus()}
      >
        <source id="audio_source_mp3" src={source} type="audio/mp3"/>
        <source id="audio_source_mp2" src={source} type="audio/mp2" />
        <source id="audio_source_ogg" src={source} type="audio/ogg" />
      </audio>
    );
  }
}

function mapsStateToProps(state) {
  return {
    active_user: state.active_user,
    selected_section: state.selected_section,
    next_sections: state.next_sections,
    is_playing: state.is_playing,
    is_loading: state.is_loading,
    first_sound: state.first_sound
  };
}
function mapDispatchToProps(dispatch){
  return bindActionCreators({changeSound, changeSoundStatus, playPause, ended, setPosition, timeUpdate}, dispatch);
}
export default connect(mapsStateToProps, mapDispatchToProps, null)(AudioTag);
