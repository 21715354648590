import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from "react-router-dom";

import Slider from "react-slick";

import PlayButtonPresentation from './play_button_presentation'
import AddListButton from './add_list_button'

import NextButton from '../components/next_arrow';
import PrevButton from '../components/prev_arrow';
import CardPlaylist from '../components/card_playlist';

import formatTime from '../helpers/helper_time';

class SliderPlaylist extends Component {
  constructor(props) {
    super(props);
  }


  formatTimeToConvert(item){
    var durée = 0
    item.sections.map(section =>
      durée += section.section.duration
    )
    return formatTime(durée)
  }


  render() {
    const len = this.props.playlists.length;
    const items_to_show = (len > 3) ? 3 : (len === 3 ? 2 : (len - 1))

    // var items_to_show = this.props.playlists.length > 3 ? 3 : (this.props.playlists.length === 3 ? 2 : (this.props.playlists.length - 1))
    const playlists = this.props.playlists.map(item =>
      <CardPlaylist playlist={item} key={item.playlist.id}/>
    );

    const settings = {
      arrows: true,
      dots: false,
      speed: 200,
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 3,
      variableWidth: true,
      draggable: true,
      nextArrow: <NextButton/>,
      prevArrow: <PrevButton/>,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 880,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1390,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
          },
        }
      ],
    };
    var className = this.props.hide_title ? "d-none" : ""
    return (
      <section className="contents__type contents__type--playlists contents__type--lg">
        <h2 className={`title--l2 ${className} title--slideshow`}>
          <span>
            <NavLink to={`/playlists`}>Les playlists de podcasts</NavLink>
          </span>
        </h2>
        <Slider {...settings} className="contents__deck">
          {playlists}
        </Slider>
      </section>
    );
  }
}

function mapsStateToProps(state) {
  return {
    playlists: state.playlists
  };
}

export default connect(mapsStateToProps, null, null)(SliderPlaylist);
