import {
  SET_SPEED,
  CHANGE_SOUND,
  SET_DEFAULT_SECTION
} from '../actions/types';

export default function(state=
  {section:
    {id:474,
    title_website:"Elson se lance : Bienvenue !",
    description:"<p>Après quelques mois de prototype, le projet #HacktheRadio laisse place à Elson, une plateforme de contenus audio, en cours de développement. Sa fondatrice, vous dévoile le projet.</p>",
    image_title: "",
    author:"<p>Elson se lance : Bienvenue</p><p>par Carine Fillot</p><p>Durée : 2 mn 20 sec</p><p>Date : Octobre 2018</p>",
    publication_date:2018-10-18,
    source_duration:144,
    duration:144,
    footer:"<p>Elson se lance : Bienvenue</p><p>par Carine Fillot</p><p>Durée : 2 mn 20 sec</p><p>Date : Octobre 2018</p>",
    start_at:0,
    end_at:144,
    photo_source:"https:\/\/res.cloudinary.com/hacktheradio/image/upload/v1539792642/vignette-elson_apuxhp.jpg",
    photo:"https:\/\/res.cloudinary.com/hacktheradio/image/upload/v1539590591/img-defaut-elson_jn3hva.jpg",
    url:"https:\/\/dl.dropbox.com/s/fhp0ewzr85jk0bi/edito-elson-18102018.mp3?dl=0"},
    player_speed:1,
    play:{id: 0, is_liked: false},
    last_user_action:{executed_at: 0}
  }, action){
  switch (action.type) {
    case CHANGE_SOUND :
      if (action.error === true) {
        return state;
      } else {
        return action.payload.next_sound;
      }
    case SET_SPEED :
      return {...state, player_speed: action.payload.player_speed}
    case SET_DEFAULT_SECTION :
      if (action.error === true) {
        return state;
      } else {
        return action.payload.data;
      }

  }
  return state;
}
