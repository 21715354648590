import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { NavLink } from "react-router-dom";

import importListe from '../../actions/pages/import_liste'
import changeListOrder from '../../actions/change_list_order'
import resetNextSections from '../../actions/reset_next_sections';
import addPile from '../../actions/add_pile';

import PlayButtonPresentation from '../play_button_presentation';
import PlayButton from '../play_button';
import Headband from '../headband';
import RemoveListButton from '../remove_list_button';
import formatTime from '../../helpers/helper_time';

class Liste extends Component {

  componentDidMount() {
    this.props.importListe(this.props.active_user)
    $('.contents__list').sortable({
      axis: 'y',
      classes: {
        'ui-sortable-handle': 'item--sortable',
        'ui-sortable-helper': 'item--dragged',
      },
      handle: '.item__title',
      items: '.list__item',
      placeholder: 'item__placeholder',
      revert: false,
      snap: true,
      update: (event, ui) => {
        var productOrder = $(".contents__list").sortable('toArray');
        this.props.changeListOrder(this.props.active_user, productOrder);
      },
    })
  }

  componentDidUpdate() {
    this.props.resetNextSections();
    let sections = [];
    this.props.lecture_list.map(item => {
      if (item.section)
        sections.push(item)
      else if (item.playlist)
        item.sections.map(section =>
          sections.push(section)
        )
      else if (item.serie)
        item.sections.map(section =>
          sections.push(section)
        )
    })
    const section = sections.filter(item => item.section.id === this.props.selected_section.section.id)[0]
    const section_index = _.indexOf(sections,section)
    sections.map((item, index) => {
      if (index > section_index){
        this.props.addPile(item)
      }
    })
  }

  calcRemainingTime(item) {
    const remaining_time = (item.last_user_action.executed_at / item.section.duration)*100
    return `${remaining_time}%`
  }

  calcPassedTimePlaylist(item) {
    let duration = 0;
    item.sections.map(section =>
      duration += (section.last_user_action.executed_at - section.section.start_at)
    )
    return duration
  }

  calcRemainingTimePlaylist(item) {
    return (this.calcTotalTimePlaylist(item) - this.calcPassedTimePlaylist(item))
  }

  calcTotalTimePlaylist(item) {
    let duration = 0;
    item.sections.map(section =>
      duration += section.section.duration
    )
    return duration
  }

  calcPlaylistTime(item) {
    const remaining_time = (this.calcPassedTimePlaylist(item) / this.calcTotalTimePlaylist(item))*100
    return `${remaining_time}%`
  }

  handleToggle(value) {
    $(`#playlist-${value}`).toggleClass("show")
  }

  handleToggleSerie(value) {
    $(`#serie-${value}`).toggleClass("show")
  }

  render() {
    let sections = [];
    if (!_.isEmpty(this.props.lecture_list)) {
      sections = this.props.lecture_list.map((item, i)  => {
        if (item.section){
          const {id, title_website, end_at, start_at } = item.section;
          const {executed_at} = item.last_user_action;
          return (
            <div id={`section${id}`} key={`section${id}`} data-id={i+1} className="list__item row no-gutters align-items-center">
            <div className="col-11 col-sm-7 col-lg-4 d-flex align-items-center">
              <div className="actions mr-2">
                <PlayButton id={id} origin="liste"/>
              </div>
              <span className="item__number d-none d-sm-block mr-3">{i + 1}</span>
              <h3 className="item__title text-truncate">
                {title_website}
              </h3>
            </div>
            <div className="col-1 col-sm-1 text-center">
              <NavLink className="item__more" to={`/sections/${id}`}>
                <span className="sr-only">
                  Plus d’</span>i<span className="sr-only">nformation
                </span>
              </NavLink>
            </div>
            <div className="d-none d-sm-block col-sm-3 mt-3 mt-sm-0">
              Cripsy sounds
            </div>
            <div className="d-none d-lg-block col-lg-1 order-5 text-right">
              <span className="sr-only">temps</span>
              <span className="d-lg-none">restant :</span>
              {formatTime(end_at - executed_at)}
            </div>
            <div className="d-none d-lg-block col-lg-1 order-lg-6 text-right">
              <span className="d-lg-none">
                <span className="sr-only">temps</span> total&nbsp;:
              </span>
              {formatTime(end_at - start_at)}
            </div>
            <div className="list__progress col-11 col-sm-12 col-lg flex-grow-1 order-5 order-sm-8 order-lg-4 mt-2 mt-lg-0">
              <div className="progress rounded-0">
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow="88"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{width: this.calcRemainingTime(item)}} >
                  <div className="progress__time--combo d-lg-none">
                    <span className="sr-only">temps </span> restant :
                    {formatTime(end_at - executed_at)}
                    <span title="sur">/</span>
                    {formatTime(end_at - start_at)}
                  </div>
                </div>
              </div>
            </div>
            <RemoveListButton id={id} origin="section"/>
          </div>
        );}
        else if (item.playlist) {
          const {id, name} = item.playlist;
          return (
            <div id={`playlist${id}`}
              key={`playlist${id}`}
              data-id={i+1}
              className="list__item list__item--group row no-gutters align-items-center">
              <div className="col-11 col-sm-7 col-lg-4 d-flex align-items-center">
                <div className="actions mr-2">
                  <PlayButton id={item.sections[0].section.id} origin="liste"/>
                </div>
                <span className="item__number d-none d-sm-block mr-3">{i + 1}</span>
                <h3 className="item__title">
                  {name}
                </h3>
              </div>
              <div className="col-1 col-sm-1 text-center">
                <NavLink className="item__more" to={`/playlists/${id}`}>
                  <span className="sr-only">
                    Plus d’</span>i<span className="sr-only">nformation
                  </span>
                </NavLink>
              </div>
              <div className="col-6 col-sm-3 mt-3 mt-sm-0">
                Playlist
                <a onClick={() => this.handleToggle(id)}
                  className="collapsed ml-3 toggle--md"
                  data-toggle="collapse"
                  data-action="showInfo"
                  href="#group-39402386">
                  <span className="sr-only">Voir le contenu de la playlist</span>
                </a>
              </div>

              <div className="d-none d-lg-block col-lg-1 order-5 text-right">
                <span className="sr-only">temps</span>
                <span className="d-lg-none">restant :</span>
                {formatTime(this.calcRemainingTimePlaylist(item))}
              </div>

              <div className="d-none d-lg-block col-lg-1 order-lg-6 text-right">
                <span className="d-lg-none">
                  <span className="sr-only">temps</span> total&nbsp;:
                </span>
                {formatTime(this.calcTotalTimePlaylist(item))}
              </div>

              <div className="list__progress col-11 col-sm-12 col-lg flex-grow-1 order-5 order-sm-8 order-lg-4 mt-2 mt-lg-0">
                <div className="progress rounded-0">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{width: this.calcPlaylistTime(item)}} >
                    <div className="progress__time--combo d-lg-none">
                      <span className="sr-only">temps </span> restant :
                      {formatTime(this.calcRemainingTimePlaylist(item))}
                      <span title="sur">/</span>
                      {formatTime(this.calcTotalTimePlaylist(item))}
                    </div>
                  </div>
                </div>
              </div>
              <RemoveListButton id={id} origin="playlist"/>
              <ul id={`playlist-`+ id} className="collapse group__list col-12 col-sm-12 col-lg-12 order-7 order-sm-9">
                {item.sections.map(section => {
                  return <li id={section.section.id} key={`section` + section.section.id} className="group__item row no-gutters">
                    <div className="item__progressbar" style={{width: this.calcRemainingTime(section)}} ></div>
                    <PlayButton id={section.section.id} origin="liste" classlist={true}/>
                    <span className="col col-lg-6">{section.section.title_website}</span>
                    <div className="item__progress d-none d-sm-block col col-lg-1 ml-auto ml-lg-0">
                      <div className="progress rounded-0">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          aria-valuemin="0"
                          aria-valuemax="767"
                          style={{width: this.calcRemainingTime(section)}} >
                          <div className="progress__time--combo d-lg-none">
                            <span className="sr-only">temps restant&nbsp;: </span>
                            {formatTime(section.section.end_at - section.last_user_action.executed_at)}
                            <span title="sur">/</span>
                            {formatTime(section.section.end_at - section.section.start_at)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-none d-lg-block col-1 text-right">
                      <span className="sr-only">temps restant&nbsp;: </span>
                      {formatTime(section.section.end_at - section.last_user_action.executed_at)}
                    </div>
                    <div className="d-none d-lg-block col-1 text-right">
                      <span className="sr-only">temps total&nbsp;: </span>
                      {formatTime(section.section.end_at - section.section.start_at)}
                    </div>
                    <RemoveListButton id={section.section.id} origin="section"/>
                  </li>;
                })}
              </ul>
            </div>
        )}
        else if (item.serie){
          const { id, name } = item.serie;
          return (
            <div id={`serie${id}`}
              key={`serie-${id}`}
              className="list__item list__item--group row no-gutters align-items-center">
            <div className="col-11 col-sm-7 col-lg-4 d-flex align-items-center">
              <div className="actions mr-2">
                <PlayButton id={item.sections[0].section.id} origin="liste"/>
              </div>
              <span className="item__number d-none d-sm-block mr-3">{i + 1}</span>
              <h3 className="item__title">
                {name}
              </h3>
            </div>
            <div className="col-1 col-sm-1 text-center">
              <NavLink className="item__more" to={`/series/${id}`}>
                <span className="sr-only">
                  Plus d’</span>i<span className="sr-only">nformation
                </span>
              </NavLink>
            </div>
            <div className="col-6 col-sm-3 mt-3 mt-sm-0">
              Série
              <a onClick={() => this.handleToggleSerie(id)}
                className="collapsed ml-3 toggle--md"
                data-toggle="collapse"
                data-action="showInfo"
                href="#group-39402386">
                <span className="sr-only">Voir le contenu de la série</span>
              </a>
            </div>

            <div className="d-none d-lg-block col-lg-1 order-5 text-right">
              <span className="sr-only">temps</span>
              <span className="d-lg-none">restant :</span>
              {formatTime(this.calcRemainingTimePlaylist(item))}
            </div>

            <div className="d-none d-lg-block col-lg-1 order-lg-6 text-right">
              <span className="d-lg-none">
                <span className="sr-only">temps</span> total&nbsp;:
              </span>
              {formatTime(this.calcTotalTimePlaylist(item))}
            </div>

            <div className="list__progress col-11 col-sm-12 col-lg flex-grow-1 order-5 order-sm-8 order-lg-4 mt-2 mt-lg-0">
              <div className="progress rounded-0">
                <div className="progress-bar"
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{width: this.calcPlaylistTime(item)}} >
                  <div className="progress__time--combo d-lg-none">
                    <span className="sr-only">temps </span> restant&nbsp;:
                    {formatTime(this.calcRemainingTimePlaylist(item))}
                    <span title="sur">/</span>
                    {formatTime(this.calcTotalTimePlaylist(item))}
                  </div>
                </div>
              </div>
            </div>
            <RemoveListButton id={id} origin="serie"/>
            <ul id={`serie-` + id} className="collapse group__list col-12 col-sm-12 col-lg-12 order-7 order-sm-9">
              {item.sections.map(section => {
                return <li id={section.section.id} key={`section` + section.section.id} className="group__item row no-gutters">
                  <div className="item__progressbar" style={{width: this.calcRemainingTime(section)}}></div>
                  <PlayButton id={section.section.id} origin="liste" classlist={true}/>
                  <span className="col col-lg-6">{section.section.title_website}</span>
                  <div className="item__progress d-none d-sm-block col col-lg-1 ml-auto ml-lg-0">
                    <div className="progress rounded-0">
                      <div className="progress-bar"
                          role="progressbar"
                          aria-valuemin="0"
                          aria-valuemax="767"
                          style={{width: this.calcRemainingTime(section)}} >
                        <div className="progress__time--combo d-lg-none">
                          <span className="sr-only">temps restant&nbsp;: </span>
                          {formatTime(section.section.end_at - section.last_user_action.executed_at)}
                          <span title="sur">/</span>
                          {formatTime(section.section.end_at - section.section.start_at)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-none d-lg-block col-1 text-right">
                    <span className="sr-only">temps restant&nbsp;: </span>
                    {formatTime(section.section.end_at - section.last_user_action.executed_at)}
                  </div>
                  <div className="d-none d-lg-block col-1 text-right">
                    <span className="sr-only">temps total&nbsp;: </span>
                    {formatTime(section.section.end_at - section.section.start_at)}
                  </div>
                  <RemoveListButton id={section.section.id} origin="section"/>
                </li>;
              })}
            </ul>
          </div>
        )}
      })
    } else {
      var serie = {};
      var duration = 0;
      sections = "Vous n'avez encore rien ajouté à votre liste";
    }
    return (
      <main className="page--list" role="main">
        <Headband type="liste"/>

        <div className="contents container">
          <section className="contents__type">
            <h2 className="title--l2 sr-only">
              <span>Ma liste de sons</span>
            </h2>
            <div className="contents__list">
              <ul className="list__headers d-none d-lg-flex row no-gutters list-unstyled">
                <li className="col-3 offset-1">
                  Titre
                </li>
                <li className="col-2 offset-1">
                  Type
                </li>
                <li className="col-1 offset-2 text-right">
                  Restant
                </li>
                <li className="col-1 text-right">
                  Total
                </li>
              </ul>
              {sections}
            </div>
          </section>
        </div>
      </main>
    );
  }
}
function mapsStateToProps(state) {
  return {
    active_user: state.active_user,
    lecture_list: state.lecture_list,
    selected_section: state.selected_section
  };
}
function mapDispatchToProps(dispatch){
  return bindActionCreators({importListe, changeListOrder, resetNextSections, addPile}, dispatch);
}
export default connect(mapsStateToProps, mapDispatchToProps, null)(Liste);
