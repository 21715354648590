import {
  ADD_PILE,
  CHANGE_SOUND,
  RESET_NEXT_SECTIONS
} from '../actions/types';

export default function(state=[], action){
  switch (action.type) {
    case CHANGE_SOUND :
      if (action.error === true) {
        return state;
      } else {
        return state.filter(item => item.section.id != action.payload.next_sound.section.id)
      }
    case ADD_PILE :
      if (action.error === true) {
        return state;
      } else {
        return [...state, action.payload]
      }
    case RESET_NEXT_SECTIONS :
      return []
  }
  return state;
}
