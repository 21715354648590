import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import importHomeSections from '../../actions/pages/import_home_sections';
import importHomeHistorique from '../../actions/pages/import_home_historique';
import importHomePlaylists from '../../actions/pages/import_home_playlists';
import importHomeSeries from '../../actions/pages/import_home_series';

import SliderSection from '../slider_section';
import SliderPlaylist from '../slider_playlist';
import SliderSerie from '../slider_serie';
import PlayButton from '../play_button';
import Headband from '../headband';

class HomeConnected extends Component {

  componentDidMount(){
    this.props.importHomeSections(this.props.active_user);
    this.props.importHomeHistorique(this.props.active_user);
    this.props.importHomePlaylists(this.props.active_user);
    this.props.importHomeSeries(this.props.active_user);
  }

  render() {
    return (
      <main className="page--home" role="main">
        <Headband type="home"/>
        <div className="contents container-fluid">
          <SliderSection min={0} max={300} title={true} type="section" hideFirst={true} />
          <SliderSection min={0} max={300} title={true} type="historique" />
          <SliderPlaylist/>
          <SliderSerie/>
        </div>
      </main>
    );
  }
}

function mapsStateToProps(state) {
  return {
    active_user: state.active_user,
    selected_section: state.selected_section,
    next_sections: state.next_sections,
    sections: state.sections,
    historique: state.historique,
    playlists: state.playlists,
    series: state.series,
    is_playing: state.is_playing,
    first_sound: state.first_sound,
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(
    {
      importHomeSections,
      importHomeHistorique,
      importHomePlaylists,
      importHomeSeries,
    },
    dispatch
  )
}
export default connect(mapsStateToProps, mapDispatchToProps, null)(HomeConnected);
