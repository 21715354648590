import {ENDED,PLAY_PAUSE} from '../actions/types';

export default function(state=false, action){
  switch (action.type) {
    case PLAY_PAUSE :
      if (action.error === true) {
        return state;
      } else {
        return !state;
      }
    case ENDED :
      return false;
  }
  return state;
}
