import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import showInfo from '../actions/show_info';

class PlayerToggle extends Component {

  render() {
    var collapsed = this.props.show_info ? "collapse" : "collapsed"
    return (
      <a onClick={() => this.props.showInfo()} href="" data-toggle="collapse" className={`interface__toggle d-none d-sm-flex ${collapsed} col-1`}>
        <span className="sr-only">Lire plus…</span>
      </a>
    );
  }
}

function mapsStateToProps(state) {
  return {
    show_info: state.show_info
  };
}
function mapDispatchToProps(dispatch){
  return bindActionCreators({showInfo}, dispatch);
}

export default connect(mapsStateToProps, mapDispatchToProps, null)(PlayerToggle);
