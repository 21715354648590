import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import jump from '../actions/jump';

class ProgressBar extends Component {
  constructor(props) {
    super(props);
  }

  formatTimePlayer(time_in_s) {
    var hrs = Math.trunc(time_in_s/3600)
    var mins = Math.trunc((time_in_s - (hrs * 3600))/60);
    var secs = Math.trunc(time_in_s - (hrs *3600) - (mins * 60));
    if (secs < 10) {secs = "0" + secs}
    if (mins < 10) {mins = "0" + mins}
    if (hrs > 0) {
      var time_str = hrs + ':' + mins + ':' + secs;
    } else {
      var time_str = mins + ':' + secs;
    }
    return time_str
  }

  skipToPosition = (e) => {
    const barre_infos  = document.getElementById('progress__track').getBoundingClientRect();
    const audio_html_dom = document.getElementById("audio-player");
    const actual_position = audio_html_dom.currentTime
    const x_length = barre_infos.width;
    const x_click = e.nativeEvent.clientX;
    const position_corrigée = x_click - barre_infos.left;
    const last_position = this.props.player_position;
    const time_sound = (this.props.selected_section.section.end_at - this.props.selected_section.section.start_at)
    const position = Math.trunc(time_sound * (position_corrigée / x_length));
    const next_position = position + this.props.selected_section.section.start_at
    audio_html_dom.currentTime = next_position
    this.props.jump(
        this.props.active_user,
        this.props.selected_section.section.id,
        actual_position,
        next_position,
        null,
        null);
  }

  render() {
    const {start_at, end_at, source_duration} = this.props.selected_section.section
    const remaining = this.props.selected_section.section.end_at - this.props.player_position
    const width_left = (start_at/source_duration*100)
    const width_rigth = ((source_duration - end_at)/source_duration*100)
    const slider_width = 100 - width_left - width_rigth
    const left = this.props.selected_section.section.start_at === 0 ? "d-none" : "left"
    const right = this.props.selected_section.section.end_at === this.props.selected_section.section.source_duration ? "d-none" : "right"
    const position_button = this.props.player_position/this.props.selected_section.section.end_at*100
    return (
      <div className="interface__progress d-flex flex-column flex-sm-column-reverse mt-1">
        <div className="track__slider progress mb-3 mb-sm-0">
          {/*Affichage début son*/}
          <div className={`section--skipped ${left} progress-bar`} style={{ width: `${width_left}%` }}></div>
          {/*Affichage slider*/}
          <div className="progress__track" id="progress__track" onClick={this.skipToPosition} style={{ width: `${slider_width}%` }}>
            <div className="slider__thumb" style={{ left: `${position_button}%` }}></div>
            <div className="slider__range" style={{ width: `${position_button}%` }}></div>
          </div>
          {/*Affichage fin son*/}
          <div className={`section--skipped ${right} progress-bar`} style={{ width: `${width_rigth}%` }}></div>
        </div>

        {/*Affichage durée*/}
        <div className="progress__infos d-flex justify-content-between mb-sm-2">
          <div className="infos__time--spent">{this.formatTimePlayer(this.props.player_position)} / {this.formatTimePlayer(end_at)}</div>
          <div className="infos__time--remaining">- {this.formatTimePlayer(remaining)}</div>
        </div>
      </div>
    );
  }
}

function mapsStateToProps(state) {
  return {
    active_user: state.active_user,
    selected_section: state.selected_section,
    player_position: state.player_position
  };
}
function mapDispatchToProps(dispatch){
  return bindActionCreators({jump}, dispatch);
}
export default connect(mapsStateToProps, mapDispatchToProps, null)(ProgressBar);
