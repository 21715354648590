import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import setSpeed from '../actions/set_speed';

class VitesseButton extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const speedSpinner = $('#control--speed')
      .spinner({
        min: 0.5,
        max: 2,
        step: 0.25,
        classes: {
          'ui-spinner': 'col-auto',
        }
      })
      .on('spin', ( event, ui ) => {
        const audio_html_dom = document.getElementById("audio-player");
        const position = audio_html_dom.currentTime;
        audio_html_dom.playbackRate = ui.value;
        this.props.setSpeed(this.props.active_user, this.props.selected_section.section.id, position, null, null, ui.value )
      });
  }

  changeSound() {
  }

  render() {
    const { player_speed } = this.props.selected_section;
    return (
      <span className="d-none d-md-flex align-items-center">
        <label
          className="controls__btn col m-0 pr-0 px-1 px-sm-2"
          htmlFor="control--speed"
        >
          vitesse
        </label>
        <input
          id="control--speed"
          className="col-auto"
          name="speed"
          value={player_speed}
          readOnly
        />
      </span>
    )
  }
}

function mapsStateToProps(state) {
  return {
    active_user: state.active_user,
    selected_section: state.selected_section
  };
}
function mapDispatchToProps(dispatch){
  return bindActionCreators({setSpeed}, dispatch);
}

export default connect(mapsStateToProps, mapDispatchToProps, null)(VitesseButton);
