import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {isBrowser, isMobileOnly, isTablet} from "react-device-detect";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import playPause from '../actions/play_pause';
import changeSound from '../actions/change_sound';
import setPosition from '../actions/set_position';
import addPile from '../actions/add_pile';
import resetNextSections from '../actions/reset_next_sections';

import loading from '../images/loading.gif'

class PlayButton extends Component {

  // Comportement du Play/Pause
  // A l'arrivée sur la page, le play pause du player lance quoi ?
  // Si son, lance le son. Sinon, il ne se passe rien.
  // Pour les autres boutons
  // Si le son est le même que celui dans le player, pause ou play avec envoi de l'uA
  // Si le son est différent, pause ou play avec envoi de l'uA,chercher au bon endroit le son.


  // Value est l'id du son ou du premier son de la playlist
  handlePlayPause(value) {
    const audio_html_dom = document.getElementById("audio-player");
    const position = audio_html_dom.currentTime;
    const action = this.props.is_playing ? "pause" : "play"
    let section, sections= [], section_index = 0;
    if (this.props.is_playing && audio_html_dom.played.length === 0) return;
    switch (this.props.origin) {
      case "section":
        sections = this.props.sections.filter(item => item.section.id == value)
        section = sections[0]
        break;
      case "related_section":
        sections = this.props.related_sections.filter(item => item.section.id == value)
        section = sections[0]
        break;
      case "historique":
        sections = this.props.historique.filter(item => item.section.id == value)
        section = sections[0]
        break;
      case "playlist":
        let playlist = this.props.playlists.filter(item => item.playlist.id == this.props.origin_id)[0]
        section = playlist.sections.filter(item => item.section.id == value)[0]
        section_index = _.indexOf(playlist.sections,section)
        sections = playlist.sections
        break;
      case "serie":
        let serie = this.props.series.filter(item => item.serie.id == this.props.origin_id)[0]
        section = serie.sections.filter(item => item.section.id == value)[0]
        section_index = _.indexOf(serie.sections,section)
        sections = serie.sections
        break;
      case "liste":
        this.props.lecture_list.map(item => {
          if (item.section)
            sections.push(item)
          else if (item.playlist)
            item.sections.map(section =>
              sections.push(section)
            )
          else if (item.serie)
            item.sections.map(section =>
              sections.push(section)
            )
        });
        section = sections.filter(item => item.section.id === value)[0]
        section_index = _.indexOf(sections,section)
        break;
      default:
        section = this.props.selected_section
    }

    if (this.props.selected_section.section.id === section.section.id) {
      this.props.is_playing ? audio_html_dom.pause() : audio_html_dom.play()
      this.props.playPause(this.props.active_user, this.props.selected_section.section.id, action, position, null, null)
    } else {
      this.props.resetNextSections()
      if (this.props.is_playing && !this.props.first_sound){
        this.props.playPause(this.props.active_user, this.props.selected_section.section.id, action, position, null, null)
      }
      sections.map((item, index) => {
        if (index == section_index) {
          this.props.changeSound(item, this.props.selected_section, position)
          this.props.setPosition(0)
        } else if (index > section_index){
          this.props.addPile(item)
        }
      })
    }
  }

  render() {
    let source_id, button;
    if (this.props.id) {
      source_id = this.props.id
    } else {
      source_id = this.props.selected_section ? this.props.selected_section.section.id : 0
    };
    const size = this.props.size === "big" ? "controls__btn mx-2" : "";
    const button_size = this.props.size === "big" ? "54" : "42";
    if (this.props.origin === "serie") {
      if (this.props.selected_section.section.id === source_id && this.props.is_playing ) {
        button =(
          <button value={source_id} className={`${size}`} data-action="play" onClick={() => this.handlePlayPause(source_id)}>
            <svg  viewBox="-50 -180 600 800" width="42" height="42" x="0" y="0"><path d="M 0,360 V 43 C 0,19 19,0 42,0 h 59 c 23,0 42,19 42,43 v 317 c 0,23 -19,42 -42,42 H 42 C 19,402 0,383 0,360 Z M 28,350 V 33 c 0,-4 2,-8 3,-12 -7,4 -13,12 -13,22 v 317 c 0,13 11,24 24,24 h 59 c 9,0 17,-5 21,-12 -4,1 -7,2 -11,2 H 53 C 39,374 28,363 28,350 Z m 148,10 V 43 C 176,19 195,0 218,0 h 58 c 24,0 43,19 43,43 v 317 c 0,23 -19,42 -43,42 h -58 c -23,0 -42,-19 -42,-42 z m 28,-10 V 33 c 0,-4 1,-8 3,-12 -8,4 -13,12 -13,22 v 317 c 0,13 11,24 24,24 h 58 c 9,0 17,-5 21,-12 -3,1 -7,2 -11,2 h -58 c -13,0 -24,-11 -24,-24 z" /></svg>
          </button>);

      } else {
        button =(
          <button value={source_id} className={`${size}`} data-action="play" onClick={() => this.handlePlayPause(source_id)}>
            <svg className="actions__ico"  viewBox="-50 -180 600 800" x="0" y="0" height="42" width="42"><path d="m 359.5,242.38242 c -61,50 -214,139 -288,166 -36,13 -54,3 -61,-36 -14,-77 -14,-254 0,-330.999999 7,-38.9999997 25,-48.9999997 61,-35.9999997 74,26.9999997 227,114.9999987 288,165.9999987 29,25 29,46 0,71 z m -28,-36 c 0,20 -221,150 -290,176 0,2 1,3 2,4 19,11 311,-157 311,-180 0,-22 -292,-189.999999 -311,-178.999999 -1,1 -2,2 -2,4 69,25 290,155.999999 290,174.999999 z" /></svg>
          </button>);
      }
    } else if (this.props.origin === "liste" && !this.props.principal) {
      var className = this.props.classlist ? "col-auto col-lg-1 offset-sm-1 offset-lg-1 align-items-center mr-1 mr-lg-0 text-lg-center" : ""
      if (this.props.selected_section.section.id === source_id && this.props.is_playing ) {
        button =(
          <button value={source_id} className={`actions__btn ${className} ${size}`} data-action="play" onClick={() => this.handlePlayPause(source_id)}>
            <svg className="actions__ico" viewBox="0 0 318.99999 402" width="42" height="42" x="0" y="0"><path d="M 0,360 V 43 C 0,19 19,0 42,0 h 59 c 23,0 42,19 42,43 v 317 c 0,23 -19,42 -42,42 H 42 C 19,402 0,383 0,360 Z M 28,350 V 33 c 0,-4 2,-8 3,-12 -7,4 -13,12 -13,22 v 317 c 0,13 11,24 24,24 h 59 c 9,0 17,-5 21,-12 -4,1 -7,2 -11,2 H 53 C 39,374 28,363 28,350 Z m 148,10 V 43 C 176,19 195,0 218,0 h 58 c 24,0 43,19 43,43 v 317 c 0,23 -19,42 -43,42 h -58 c -23,0 -42,-19 -42,-42 z m 28,-10 V 33 c 0,-4 1,-8 3,-12 -8,4 -13,12 -13,22 v 317 c 0,13 11,24 24,24 h 58 c 9,0 17,-5 21,-12 -3,1 -7,2 -11,2 h -58 c -13,0 -24,-11 -24,-24 z" /></svg>
          </button>);

      } else {
        button =(
          <button value={source_id} className={`actions__btn ${className} ${size}`} data-action="play" onClick={() => this.handlePlayPause(source_id)}>
            <svg className="actions__ico"  viewBox="0 0 381.25001 413.76485" x="0" y="0" height="42" width="42"><path d="m 359.5,242.38242 c -61,50 -214,139 -288,166 -36,13 -54,3 -61,-36 -14,-77 -14,-254 0,-330.999999 7,-38.9999997 25,-48.9999997 61,-35.9999997 74,26.9999997 227,114.9999987 288,165.9999987 29,25 29,46 0,71 z m -28,-36 c 0,20 -221,150 -290,176 0,2 1,3 2,4 19,11 311,-157 311,-180 0,-22 -292,-189.999999 -311,-178.999999 -1,1 -2,2 -2,4 69,25 290,155.999999 290,174.999999 z" /></svg>
          </button>);
      }
    } else if (this.props.origin === "player") {
        if (this.props.selected_section.section.id === source_id && this.props.is_playing ) {
          button =(
            <button value={source_id} className={`d-flex align-items-center ${size}`} data-action="play" onClick={() => this.handlePlayPause(source_id)}>
              <span className="sr-only">pause</span>
              <svg width={button_size} height={button_size} viewBox="0 0 847 847"><path d="M16 423c0,-225 182,-407 407,-407 225,0 408,182 408,407 0,225 -183,408 -408,408 -225,0 -407,-183 -407,-408zm248 159l0 -317c0,-24 19,-43 42,-43l59 0c23,0 42,19 42,43l0 317c0,23 -19,42 -42,42l-59 0c-23,0 -42,-19 -42,-42zm28 -10l0 -317c0,-4 2,-8 3,-12 -7,4 -13,12 -13,22l0 317c0,13 11,24 24,24l59 0c9,0 17,-5 21,-12 -4,1 -7,2 -11,2l-58 0c-14,0 -25,-11 -25,-24zm148 10l0 -317c0,-24 19,-43 42,-43l58 0c24,0 43,19 43,43l0 317c0,23 -19,42 -43,42l-58 0c-23,0 -42,-19 -42,-42zm28 -10l0 -317c0,-4 1,-8 3,-12 -8,4 -13,12 -13,22l0 317c0,13 11,24 24,24l58 0c9,0 17,-5 21,-12 -3,1 -7,2 -11,2l-58 0c-13,0 -24,-11 -24,-24z"/></svg>
            </button>);
        } else {
          button =(
            <button value={source_id} className={`d-flex align-items-center ${size}`} data-action="play" onClick={() => this.handlePlayPause(source_id)}>
              <span className="sr-only">play</span>
              <svg width={button_size} height={button_size} viewBox="0 0 847 847"  version="1.0" ><path d="M423 19c223,0 404,181 404,404 0,223 -181,404 -404,404 -223,0 -404,-181 -404,-404 0,-223 181,-404 404,-404zm169 440c-61,50 -214,139 -288,166 -36,13 -54,3 -61,-36 -14,-77 -14,-254 0,-331 7,-39 25,-49 61,-36 74,27 227,115 288,166 29,25 29,46 0,71zm-28 -36c0,20 -221,150 -290,176 0,2 1,3 2,4 19,11 311,-157 311,-180 0,-22 -292,-190 -311,-179 -1,1 -2,2 -2,4 69,25 290,156 290,175zm-141 -372c211,0 383,169 387,379 0,-2 0,-4 0,-7 0,-213 -173,-386 -387,-386 -213,0 -386,173 -386,386 0,3 0,5 0,7 4,-210 175,-379 386,-379z"/></svg>
            </button>);
        }
    } else {
      if (this.props.selected_section.section.id === source_id && this.props.is_playing ) {
        button =(
          <button value={source_id} className={`d-flex align-items-center ${size}`} data-action="play" onClick={() => this.handlePlayPause(source_id)}>
            <span className="sr-only">pause</span>
            <svg width={button_size} height={button_size} viewBox="0 0 847 847"><path d="M16 423c0,-225 182,-407 407,-407 225,0 408,182 408,407 0,225 -183,408 -408,408 -225,0 -407,-183 -407,-408zm248 159l0 -317c0,-24 19,-43 42,-43l59 0c23,0 42,19 42,43l0 317c0,23 -19,42 -42,42l-59 0c-23,0 -42,-19 -42,-42zm28 -10l0 -317c0,-4 2,-8 3,-12 -7,4 -13,12 -13,22l0 317c0,13 11,24 24,24l59 0c9,0 17,-5 21,-12 -4,1 -7,2 -11,2l-58 0c-14,0 -25,-11 -25,-24zm148 10l0 -317c0,-24 19,-43 42,-43l58 0c24,0 43,19 43,43l0 317c0,23 -19,42 -43,42l-58 0c-23,0 -42,-19 -42,-42zm28 -10l0 -317c0,-4 1,-8 3,-12 -8,4 -13,12 -13,22l0 317c0,13 11,24 24,24l58 0c9,0 17,-5 21,-12 -3,1 -7,2 -11,2l-58 0c-13,0 -24,-11 -24,-24z"/></svg>
          </button>);
      } else {
        button =(
          <button value={source_id} className={`d-flex align-items-center ${size}`} data-action="play" onClick={() => this.handlePlayPause(source_id)}>
            <span className="sr-only">play</span>
            <svg width={button_size} height={button_size} viewBox="0 0 847 847"  version="1.0" ><path d="M423 19c223,0 404,181 404,404 0,223 -181,404 -404,404 -223,0 -404,-181 -404,-404 0,-223 181,-404 404,-404zm169 440c-61,50 -214,139 -288,166 -36,13 -54,3 -61,-36 -14,-77 -14,-254 0,-331 7,-39 25,-49 61,-36 74,27 227,115 288,166 29,25 29,46 0,71zm-28 -36c0,20 -221,150 -290,176 0,2 1,3 2,4 19,11 311,-157 311,-180 0,-22 -292,-190 -311,-179 -1,1 -2,2 -2,4 69,25 290,156 290,175zm-141 -372c211,0 383,169 387,379 0,-2 0,-4 0,-7 0,-213 -173,-386 -387,-386 -213,0 -386,173 -386,386 0,3 0,5 0,7 4,-210 175,-379 386,-379z"/></svg>
          </button>);
      }
    }
    return (
      button
    )
  }
}

function mapsStateToProps(state) {
  return {
    active_user: state.active_user,
    selected_section: state.selected_section,
    is_playing: state.is_playing,
    is_loading: state.is_loading,
    sections: state.sections,
    historique: state.historique,
    related_sections: state.related_sections,
    playlists: state.playlists,
    series: state.series,
    lecture_list: state.lecture_list,
    first_sound: state.first_sound
  };
}
function mapDispatchToProps(dispatch){
  return bindActionCreators({playPause, changeSound, setPosition, addPile, resetNextSections}, dispatch);
}
export default connect(mapsStateToProps, mapDispatchToProps, null)(PlayButton);
