import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import showInfo from '../actions/show_info';

class PlayerToggleMobile extends Component {

  toggleInfo() {
    this.props.showInfo();
  }

  render() {
    var collapsed = this.props.show_info ? "collapse" : "collapsed"
    return (
      <div className="player__title player__title__mobile d-flex align-items-center d-sm-none mt-sm-2 order-2">
        <a onClick={() => this.props.showInfo()} href="" style={{cursor:'pointer'}} data-toggle="collapse" className={`interface__toggle show d-sm-none ${collapsed}`}>
          <span className="sr-only">Lire plus…</span>
        </a>
        {this.props.selected_section.section.title_website}
      </div>
    );
  }
}
function mapsStateToProps(state) {
  return {
    selected_section: state.selected_section,
    show_info: state.show_info
  };
}
function mapDispatchToProps(dispatch){
  return bindActionCreators({showInfo}, dispatch);
}

export default connect(mapsStateToProps, mapDispatchToProps, null)(PlayerToggleMobile);
