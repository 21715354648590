import {
  HAS_MORE_SECTIONS,
  IMPORT_SECTIONS_PAGE,
  INCREASE_ACTIVE_SECTIONS_PAGE_NR,
  IS_FETCHING_SECTIONS,
} from '../types';
import axios from 'axios';
import URL from '../../helpers/helper_url'

export const importSectionsPage = (user_id, min_duration, max_duration, sections_active_page, items_count_per_page) => (dispatch, getState) => {
  console.log("Import section page")
  const url = URL + `/sections_page`;
  // const section_pagination = new Pagination(totalItemsCount, sectionsActivePage,itemsCountPerPage)
  const params = {
    params: {
      user_id,
      sections_active_page,
      items_count_per_page,
      min_duration,
      max_duration,
    }
  }
  const request = axios.get(url, params);
  //lock while waiting for server's response
  dispatch({
    type: IS_FETCHING_SECTIONS,
    payload: true
  })
  dispatch({
    type: IMPORT_SECTIONS_PAGE,
    payload: request
  }).then(
    response => {
      if(response.payload.data.sections_for_player.length === 0) {
        dispatch({
          type: HAS_MORE_SECTIONS,
          payload: false
        })
      } else {
        dispatch({
          type: INCREASE_ACTIVE_SECTIONS_PAGE_NR,
          payload: sections_active_page
        })
        //releasing lock
        dispatch({
          type: IS_FETCHING_SECTIONS,
          payload: false
        });
      }
    },
    error => {
      distpach({
        type: HAS_MORE_SECTIONS,
        payload: false
      })
      dispatch({
        type: IS_FETCHING_SECTIONS,
        payload: true
      })
    }
  );
}
