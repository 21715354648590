import {IMPORT_PLAYLISTS} from '../types';
import axios from 'axios';
import URL from '../../helpers/helper_url'

export default function importPlaylists(user_id) {
  console.log("Import playlists")
  const url = URL + `/playlists`;
  const request = axios.get(url, {params: {user_id: user_id}});

  return {
    type: IMPORT_PLAYLISTS,
    payload: request
  };
};
