export const IMPORT_COLLECTION = 'IMPORT_COLLECTION';
import axios from 'axios';
import URL from '../../helpers/helper_url'

export default function importCollection(user_id, collection_id) {
  console.log("Import collection")
  const url = URL + `/collections/${collection_id}`;
  const request = axios.get(url, {params: {user_id: user_id}});

  return {
    type: IMPORT_COLLECTION,
    payload: request
  };
};
