import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from "react-router-dom";

import Slider from "react-slick";
import AddListButton from '../containers/add_list_button';

import NextButton from '../components/next_arrow';
import PrevButton from '../components/prev_arrow';
import renderHTML from '../helpers/helper_html';
import formatTime from '../helpers/helper_time';


class SliderSection extends Component {
  constructor(props) {
    super(props);
  }


  formatTimeToConvert(item){
    let durée = 0;
    item.sections.map(section =>
      durée += section.section.duration
    )
    return formatTime(durée)
  }

  render() {

    const series = this.props.series.map(item =>
      <div className="e-card card--series card--title-opacity" key={`serie-` + item.serie.id}>
        <div className="card__title" style={{backgroundImage: `url(${item.serie.photo})`}}>
          <div className="title__holder">
            <h3 className="title__text text-truncate">
              <NavLink to={`/series/${item.serie.id}`}>{item.serie.name}</NavLink>
            </h3>
          </div>
        </div>
        <div className="card__body">
          <div className="card__description card__description--cropped" dangerouslySetInnerHTML={renderHTML(item.serie.description)}></div>
          <div className="actions card__actions">
            <div className="actions__sound actions">
              <AddListButton id={item.serie.id} origin="serie" icon={true}/>
            </div>
            <NavLink className="collapsed" to={`/series/${item.serie.id}`}  data-toggle="">
              <span className="sr-only">Lire plus…</span>
            </NavLink>
          </div>
        </div>
      </div>
    );
    const settings = {
      arrows: true,
      dots: false,
      speed: 200,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      variableWidth: false,
      slide: '.e-card',
      draggable: true,
      nextArrow: <NextButton/>,
      prevArrow: <PrevButton/>,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 740,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1080,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
          },
        },
        {
          breakpoint: 1390,
          settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
          },
        }
      ],
    };
    const className = this.props.hide_title ? "d-none" : ""
    return (
      <section className="contents__type contents__type--series contents__type--md">
        <h2 className={`title--l2 ${className} title--slideshow`}>
          <span>
            <NavLink to={`/series`}>Les séries</NavLink>
          </span>
        </h2>
        <Slider {...settings} className="contents__deck">
          {series}
        </Slider>
      </section>
    );
  }
}

function mapsStateToProps(state) {
  return {
    series: state.series
  };
}

export default connect(mapsStateToProps, null, null)(SliderSection);
