import {IMPORT_RELATED_SECTIONS} from '../types';
import axios from 'axios';
import URL from '../../helpers/helper_url'

export default function importRelatedSections(user_id, section_id) {
  const url = URL + `/related_sections`;
  var params = {
    user_id: user_id,
    id: section_id
  }
  const request = axios.get(url, {params: params});

  return {
    type: IMPORT_RELATED_SECTIONS,
    payload: request
  };
};
