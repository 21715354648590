import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import importHomeDisconnectedSections from '../../actions/pages/import_home_disconnected_sections';
import importHomeDisconnectedPlaylists from '../../actions/pages/import_home_disconnected_playlists';
import importHomeDisconnectedSeries from '../../actions/pages/import_home_disconnected_series';

import SliderSection from '../slider_section';
import SliderPlaylist from '../slider_playlist';
import SliderSerie from '../slider_serie';
import PlayButton from '../play_button';
import PlayButtonPresentation from '../play_button_presentation';
import Headband from '../headband';

import renderHTML from '../../helpers/helper_html';

class HomeDisconnected extends Component {

  componentDidMount(){
    this.props.importHomeDisconnectedSections();
    this.props.importHomeDisconnectedPlaylists();
    this.props.importHomeDisconnectedSeries();
  }

  render() {
  var section_id = null
  var section_style = null
  var section_title = null
  var section_description = null
  if (this.props.sections.length > 0) {
    section_id = this.props.sections[0].section.id
    section_title = this.props.sections[0].section.title_website
    section_style = {backgroundImage: `url(${this.props.sections[0].section.photo})`}
    section_description = this.props.sections[0].section.description
  }

  var playlist_id = null
  var playlist_style = null
  var playlist_title = null
  var playlist_description = null
  if (this.props.playlists.length > 0) {
    playlist_id = this.props.playlists[0].playlist.id
    playlist_title = this.props.playlists[0].playlist.name
    playlist_style = {backgroundImage: `url(${this.props.playlists[0].playlist.photo})`}
    playlist_description = this.props.playlists[0].playlist.description
  }

  var serie_id = null
  var serie_style = null
  var serie_title = null
  var serie_description = null
  if (this.props.series.length > 0) {
    serie_id = this.props.series[0].serie.id
    serie_title = this.props.series[0].serie.name
    serie_style = {backgroundImage: `url(${this.props.series[0].serie.photo})`}
    serie_description = this.props.series[0].serie.description
  }

    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 style={{marginBottom: 5}}>LE SERVICE D'ECOUTE DE PODCASTS</h2>
              <h3>Un prototype pour valider la recommandation éditoriale du projet.</h3>
              <p>Depuis sa création, Elson s'inscrit une volonté de valoriser les actifs du podcast, qu'ils soient issus du
                champ de la radio ou du podcast natif, et d'en dévoiler la valeur patrimoniale. Notre enjeu d'innovation est de proposer une expérience d’écoute inédite à partir d’une curation humaine et algorithmique.</p>
              <p>Cette version "prototype" sur laquelle vous pouvez vous inscrire, a été testée auprès des 2000 utilisateurs qui reçoivent des recommandations de "sons du jour", de playlists thématiques et de séries sonores repérées et mises en avant par notre collectif d'écoute "Oreilles Curieuses".</p>
              <p> Nous travaillons à la conception et au développement d'une application mobile pour 2022.</p>
            </div>
          </div>
          <div className="row home__sounds">
            <div className="sounds__podcasts col-12 col-md-4 p-md-4 py-sm-5 d-flex flex-column">
              <h2 className="order-1" style={{marginBottom: 5}}>Le dernier crispy son :</h2>
              <div className="order-3" dangerouslySetInnerHTML={renderHTML(section_description)} />
              <div className=" e-card card--title-middle card--title-play order-2 mt-1 mb-2">
                <div className="card__title" style={section_style}>
                  <div className="title__holder">
                    <a className="title__text title__text__home" href={`/sections/${section_id}`}>{section_title}</a>
                    <PlayButton id={section_id} origin={"section"}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="sounds__voices col-12 col-md-4 p-md-4 py-sm-5 d-flex flex-column">
              <h2 className="order-2" style={{marginBottom: 5}}>La dernière playlist :</h2>
              <div className="order-4" dangerouslySetInnerHTML={renderHTML(playlist_description)} />
              <div className="e-card card--title-middle card--title-play order-3 mt-1 mb-2">
                <div className="card__title" style={playlist_style}>
                  <div className="title__holder">
                    <a className="title__text title__text__home" href={`/playlists/${playlist_id}`}>{playlist_title}</a>
                    <PlayButtonPresentation id={playlist_id} origin="playlist" onHome={true}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="sounds__voices col-12 col-md-4 p-md-4 py-sm-5 d-flex flex-column">
              <h2 className="order-2" style={{marginBottom: 5}}>La dernière série :</h2>
              <div className="order-4" dangerouslySetInnerHTML={renderHTML(serie_description)} />
              <div className="e-card card--title-middle card--title-play order-3 mt-1 mb-2">
                <div className="card__title" style={serie_style}>
                  <div className="title__holder">
                    <a className="title__text title__text__home" href={`/series/${serie_id}`}>{serie_title}</a>
                    <PlayButtonPresentation id={serie_id} origin="serie" onHome={true}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 home__call-to-action">
              <a className="call-to-action__button btn" href="/registrations/new" style={{marginRight: 20}}>S'inscrire</a>
              <a className="call-to-action__button btn orange" href="/sessions/new">Se connecter</a>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapsStateToProps(state) {
  return {
    active_user: state.active_user,
    selected_section: state.selected_section,
    sections: state.sections,
    playlists: state.playlists,
    series: state.series,
    is_playing: state.is_playing,
    first_sound: state.first_sound,
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(
    {
      importHomeDisconnectedSections,
      importHomeDisconnectedPlaylists,
      importHomeDisconnectedSeries,
    },
    dispatch
  )
}
export default connect(mapsStateToProps, mapDispatchToProps, null)(HomeDisconnected);
