import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import HomeConnected from '../containers/contenu/home_connected';
import Section from '../containers/contenu/section';
import Sections from '../containers/contenu/sections';
import Playlist from '../containers/contenu/playlist';
import Playlists from '../containers/contenu/playlists';
import Serie from '../containers/contenu/serie';
import Series from '../containers/contenu/series';
import Collection from '../containers/contenu/collection';
import Collections from '../containers/contenu/collections';
import Liste from '../containers/contenu/liste';
import Cgu from '../containers/contenu/cgu';
import Presse from '../containers/contenu/presse';
import Podcast from '../containers/contenu/podcast';

// A chaque changement de page, les state "sections", "playlists", "series", "historique", "liste" et "collections" sont mis à jour.
// Le composant à l'url correspondant à la page est appelé.
// Tout les fichiers correspondants sont dans le dossier containers/contenu
// API est modifié pour que sur chaque méthode elle renvoie les valeurs de state pour la page en question.

export default class Contenu extends Component {
  render() {
    return (
      <>
        <Route exact={true} path="/" component={HomeConnected} />
        <Route exact={true} path="/sections" component={Sections} />
        <Route exact={true} path="/sections/:id" component={Section} />
        <Route exact={true} path="/playlists" component={Playlists} />
        <Route exact={true} path="/playlists/:id" component={Playlist} />
        <Route exact={true} path="/series" component={Series} />
        <Route exact={true} path="/series/:id" component={Serie} />
        <Route exact={true} path="/collections" component={Collections} />
        <Route exact={true} path="/collections/:id" component={Collection} />
        <Route exact={true} path="/my_list" component={Liste} />
        <Route exact={true} path="/cgu" component={Cgu} />
        <Route exact={true} path="/presse" component={Presse} />
        <Route exact={true} path="/podcasts" component={Podcast} />
      </>
    );
  }
}
