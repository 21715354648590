import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import importCollection from '../../actions/pages/import_collection'
import playPause from '../../actions/play_pause'
import changeSound from '../../actions/change_sound'
import addPile from '../../actions/add_pile'


class Collection extends Component {

  componentDidMount() {
    this.props.importCollection(this.props.active_user, this.props.match.params.id)
  }

  handlePile(value) {
    const section = this.props.sections.filter(item => item.section.id === value)
    if (!_.isEmpty(section)) {
      this.props.addPile(section[0])
      return
    }
  }

  render() {
    console.log(this.props.collections)
    let sections;
    if (!_.isEmpty(this.props.collections)) {
      sections = this.props.collections[0].sections.map(item =>
        <div key={item.section.id} className="sections_test">
          <Link to={"/sections/" + item.section.id}> {item.section.title_website} </Link>
          <p
            value={item.section.id}
            onClick={() => this.PlayPauseSection(item.section.id)} > {(this.props.is_playing && this.props.selected_section.section.id === item.section.id) ? "Pause" : "Play"} </p>
        </div>
      )
    } else {
      sections = ""
    }
    // var className = this.props.selected_section ? "" : "d-none"
    // var section = this.props.sections.map(item =>
    //   <div key={item.section.id} className="sections_test">
    //     <Link to={"/sections/" + item.section.id}> {item.section.card_title} </Link>
    //     <p value={item.section.id} onClick={() => this.handlePlayPause(item.section.id)} > {(this.props.is_playing && this.props.selected_section.section.id === item.section.id) ? "Pause" : "Play"} </p>
    //     <p className={className} value={item.section.id} onClick={() => this.handlePile(item.section.id)} > Mettre le son dans la pile (masquer si dans la pile) </p>
    // </div>
    // )
    return (
      <div>
        <p> Une collection de ELSON </p>
        {sections}
      </div>
    );
  }
}
function mapsStateToProps(state) {
  return {
    active_user: state.active_user,
    selected_section: state.selected_section,
    next_sections: state.next_sections,
    sections: state.sections,
    is_playing: state.is_playing,
    first_sound: state.first_sound,
    collections: state.collections
  };
}
function mapDispatchToProps(dispatch){
  return bindActionCreators({importCollection,playPause,changeSound, addPile}, dispatch);
}
export default connect(mapsStateToProps, mapDispatchToProps, null)(Collection);
