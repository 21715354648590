import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import PlayButton from '../containers/play_button';

import formatTime from '../helpers/helper_time';
import renderHTML from '../helpers/helper_html';

import PlayButtonPresentation from './play_button_presentation'
import AddListButton from './add_list_button'

import Credits from '../components/credits';

class Headband extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_info: false,
    }
  }
// - un éventuel `span.sr-only` dans le `h2` pour les mises en avant de la home
// (ou autre dans le futur), pour contextualiser le headband si nécessaire.
// - `.e-card` peut avoir des classes supplémentaires en fonction du type de `.headband`
// pour le `card__title` ou autres variations (pareil pour les cartes).
// - un éventuel bouton play (pareil pour les cartes).
// - `.headband__actions` peut avoir du contenu ou pas mais doit toujours être présent.
// - `.headband__metas` peut avoir du contenu ou pas mais doit toujours être présent.
  render() {
    let photo, title_website,duration,id,photo_source
    let className = ""
    let style = {}
    let link = "Lancer ma liste de lecture"
    let picture = ""
    let title = ""
    let description = ""
    let duree = 0
    let sounds_count = ""
    let headband_title = "Le crispy sonssss du jour"
    let button_presentation = ""
    let button_list = ""
    let button_play = ""
    let meta_classname = ""
    let add_list_section = ""
    let more_informations = ""
    let origin_picture = ""
    let footer = ""
    let visible, invisible, padding;
    if (this.state.show_info === true) {
      visible = "d-none"
      invisible = "d-flex"
      padding = "pl-3 pr-3"
    }
    else {
      visible = ""
      invisible = "d-none"
      padding = "p-3 p-lg-5"
    }

    if (this.props.type === "section") {
      link = "Découvrez une recommandation elson"
      className = "card--title-middle card--title-play"
      headband_title = "Découvrez une recommandation elson"
      if (!_.isEmpty(this.props.sections.filter(item => item.section.id == this.props.id))) {
        let section = this.props.sections.filter(item => item.section.id == this.props.id)[0].section
        picture = section.photo
        title = section.title_website
        description = section.description
        duree = section.duration
        id = section.id
        headband_title =  title;
        button_play = <PlayButton id={id} origin="section" />;
        link = <NavLink to={`/${this.props.type}s/${id}`}>{title}</NavLink>;
        style = {backgroundImage: `url(${picture})`};
        add_list_section = <AddListButton id={id} origin="section" icon={true}/>;
        more_informations =
          <svg onClick={() => this.setState({show_info: true})} className="ml-3" width="38" height="38" viewBox="0 0 106 106" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M53 106C82.2711 106 106 82.2711 106 53C106 23.7289 82.2711 0 53 0C23.7289 0 0 23.7289 0 53C0 82.2711 23.7289 106 53 106Z" fill="#F74E37"/>
            <path d="M46.0821 43.9272H58.9317L52.2911 72.6147H57.8028L56.5079 78.3257H44.2559L50.8966 49.6382H44.7208L46.0821 43.9272ZM52.4239 31.8081C52.4239 31.2104 52.5346 30.646 52.7559 30.1147C52.9994 29.5835 53.3204 29.1297 53.7188 28.7534C54.1173 28.355 54.5821 28.0451 55.1134 27.8237C55.6446 27.5802 56.2201 27.4585 56.8399 27.4585C57.4376 27.4585 58.002 27.5802 58.5333 27.8237C59.0645 28.0451 59.5183 28.355 59.8946 28.7534C60.2931 29.1297 60.603 29.5835 60.8243 30.1147C61.0678 30.646 61.1895 31.2104 61.1895 31.8081C61.1895 32.4058 61.0678 32.9813 60.8243 33.5347C60.603 34.0659 60.2931 34.5418 59.8946 34.9624C59.5183 35.3608 59.0645 35.6818 58.5333 35.9253C58.002 36.1688 57.4376 36.2905 56.8399 36.2905C56.2201 36.2905 55.6446 36.1688 55.1134 35.9253C54.5821 35.6818 54.1173 35.3608 53.7188 34.9624C53.3204 34.5418 52.9994 34.0659 52.7559 33.5347C52.5346 32.9813 52.4239 32.4058 52.4239 31.8081Z" fill="#F2F2F2"/>
          </svg>;
        origin_picture = section.photo_source
        footer = section.footer
      }
    } else if (this.props.type === "playlist") {
      className = "card--title-sr-only"
      link = "Découvrez une playlist elson"
      headband_title = "Découvrez une playlist elson"
      button_list = <AddListButton id={this.props.id} origin="playlist" icon={false} onPage={true} />;
      button_presentation = <PlayButtonPresentation id={this.props.id} origin="playlist" onPage={true}/>;
      if (!_.isEmpty(this.props.playlists)) {
        picture = this.props.playlists.filter(item => item.playlist.id == this.props.id)[0].playlist.photo
        title = this.props.playlists.filter(item => item.playlist.id == this.props.id)[0].playlist.name
        description = this.props.playlists.filter(item => item.playlist.id == this.props.id)[0].playlist.description
        this.props.playlists.filter(item => item.playlist.id == this.props.id)[0].sections.map(item =>
          duree += item.section.duration
        )
        sounds_count = this.props.playlists.filter(item => item.playlist.id == this.props.id)[0].sections.length + ' sons - '
        headband_title = title
        style = {backgroundImage: `url(${picture})`}
      }
    } else if (this.props.type === "serie") {
      className = "card--title-opacity"
      link = "Découvrez une série conseillée par elson"
      headband_title = "Découvrez une série conseillée par elson"
      button_list = <AddListButton id={this.props.id} origin="serie" icon={false} onPage={true} />;

      if (!_.isEmpty(this.props.series)) {
        let serie = this.props.series.filter(item => item.serie.id == this.props.id)[0]
        if (serie.presentation_section.section.id != 0) {
          button_presentation = <PlayButtonPresentation id={this.props.id} origin="serie" onPage={true}/>;
        }
        picture = serie.serie.photo
        title = serie.serie.name
        description = serie.serie.description
        serie.sections.map(item =>
          duree += item.section.duration
        )
        id = serie.serie.id
        sounds_count = serie.sections.length + ' sons - '
        headband_title = title
        link = <NavLink to={`/${this.props.type}s/${this.props.id}`}>{title}</NavLink>;
        style = {backgroundImage: `url(${picture})`}
      }
    } else if (this.props.type === "liste") {
      className = "card--plain card--title-middle card--title-play"
      description = "<p>Bienvenue dans votre espace de lecture. Vous pouvez gérer ici votre liste de lecture personnelle. En surfant sur le site, vous pouvez ajouter des sons crispy, des playlists ou des séries, puis lancer votre liste, ré-ordonner les sons, mettre en pause et reprendre votre écoute.</p>"
      meta_classname = "d-none"
      if (!_.isEmpty(this.props.lecture_list)) {
        let list_sections = []
        this.props.lecture_list.map(item => {
          if (item.section)
            list_sections.push(item)
          else if (item.playlist)
            item.sections.map(section =>
              list_sections.push(section)
            )
          else if (item.serie)
            item.sections.map(section =>
              list_sections.push(section)
            )
        })
        id = list_sections[0].section.id
        button_play = <PlayButton id={id} origin="liste" principal={true} />;
      }
    } else if (this.props.type === "home") {
      link = "Le crispy son du jour"
      className = "card--title-middle card--title-play"
      headband_title = "Le crispy son du jour"
      if (!_.isEmpty(this.props.sections)) {
        // {photo, title_website,description,duration,id,photo_source,footer} = this.props.sections[0].section
        picture = this.props.sections[0].section.photo
        title = this.props.sections[0].section.title_website
        duree = this.props.sections[0].section.duration
        description = this.props.sections[0].section.description
        footer = this.props.sections[0].section.footer
        id = this.props.sections[0].section.id
        origin_picture = this.props.sections[0].section.photo_source
        button_play = <PlayButton id={this.props.sections[0].section.id} origin="section" />;
        link = <NavLink to={`/sections/${id}`}>{title}</NavLink>;
        style = {backgroundImage: `url(${picture})`}
        add_list_section = <AddListButton id={this.props.sections[0].section.id} origin="section" icon={true}/>
        more_informations =
          <svg onClick={() => this.setState({show_info: true})} className="ml-3" width="38" height="38" viewBox="0 0 106 106" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M53 106C82.2711 106 106 82.2711 106 53C106 23.7289 82.2711 0 53 0C23.7289 0 0 23.7289 0 53C0 82.2711 23.7289 106 53 106Z" fill="#F74E37"/>
            <path d="M46.0821 43.9272H58.9317L52.2911 72.6147H57.8028L56.5079 78.3257H44.2559L50.8966 49.6382H44.7208L46.0821 43.9272ZM52.4239 31.8081C52.4239 31.2104 52.5346 30.646 52.7559 30.1147C52.9994 29.5835 53.3204 29.1297 53.7188 28.7534C54.1173 28.355 54.5821 28.0451 55.1134 27.8237C55.6446 27.5802 56.2201 27.4585 56.8399 27.4585C57.4376 27.4585 58.002 27.5802 58.5333 27.8237C59.0645 28.0451 59.5183 28.355 59.8946 28.7534C60.2931 29.1297 60.603 29.5835 60.8243 30.1147C61.0678 30.646 61.1895 31.2104 61.1895 31.8081C61.1895 32.4058 61.0678 32.9813 60.8243 33.5347C60.603 34.0659 60.2931 34.5418 59.8946 34.9624C59.5183 35.3608 59.0645 35.6818 58.5333 35.9253C58.002 36.1688 57.4376 36.2905 56.8399 36.2905C56.2201 36.2905 55.6446 36.1688 55.1134 35.9253C54.5821 35.6818 54.1173 35.3608 53.7188 34.9624C53.3204 34.5418 52.9994 34.0659 52.7559 33.5347C52.5346 32.9813 52.4239 32.4058 52.4239 31.8081Z" fill="#F2F2F2"/>
          </svg>
      }
    }
    return (
      <div className={`headband e-card ${className} container-fluid`}>
        <div className="row">
          <div className="headband__img card__title col-12 col-md-5 col-lg-4 " style={style} >
            <div className="title__holder">
              <h2 className="title__text">
                {link}
              </h2>
              {button_play}
            </div>
          </div>
          <div className="headband__info col-12 col-md-7 col-lg-8 d-flex flex-column justify-content-between">
            <div className="headband__metas row align-items-baseline">
              <div className={`${meta_classname} headband__title col-12 col-sm-6 col-md-7 col-lg-8 py-3 pl-lg-5`}>
                {headband_title}
              </div>
              <time className={`${meta_classname} card__time card__time--total col-12 col-sm-6 col-md-5 col-lg-4 text-sm-right pb-3 pb-sm-0`}  dateTime="PT2M">
                {sounds_count} Durée : {formatTime(duree)}
              </time>
            </div>
            <div className={`headband__description ${padding} row`}>
              <div className={`${visible} col-12 p-0`} dangerouslySetInnerHTML={renderHTML(description)}>
              </div>
              <div className={`${visible} col p-0 mt-3`}>
                {add_list_section}
                {more_informations}
              </div>
              <div className={`${invisible} col-12 p-0 justify-content-end`}>
                <svg onClick={() => this.setState({show_info: false})} version="1.1" id="Calque_1" x="0px" y="0px" width="48" height="48" viewBox="0 0 595.275 841.891" enableBackground="new 0 0 595.275 841.891">
                  <polygon fill="#FFFFFF" points="519.771,500.915 365.422,346.536 519.771,192.169 452.017,124.408 297.656,278.769 143.295,124.408
                  75.535,192.169 229.901,346.536 75.505,500.915 143.259,568.669 297.656,414.284 452.017,568.669 "/>
                </svg>
              </div>
              <div className={`${invisible} col p-0`}>
                <Credits photo={origin_picture} footer={footer}/>
              </div>
            </div>
            <div className="headband__actions actions row">
              {button_presentation}
              {button_list}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapsStateToProps(state) {
  return {
    sections: state.sections,
    playlists: state.playlists,
    series: state.series,
    collections: state.collections,
    lecture_list: state.lecture_list
  };
}
export default connect(mapsStateToProps, null, null)(Headband);
