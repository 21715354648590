import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

import PlayButton from '../containers/play_button';
import PlayButtonPresentation from '../containers/play_button_presentation';

import AddListButton from '../containers/add_list_button';

import Credits from '../components/credits';

import formatTime from '../helpers/helper_time';
import renderHTML from '../helpers/helper_html';

export default class CardPlaylist extends Component {
  state = { show_info: false }

  formatTimeToConvert(item){
    let duration = 0
    item.sections.map(section =>
      duration += section.section.duration
    )
    return formatTime(duration)
  }

  render() {
    const {playlist} = this.props;
    const {id, name, photo, description} = this.props.playlist.playlist;
    let playlists;
    if (this.state.show_info === false) {
      playlists =
      <div className="e-card card--playlist card--title-opaque" key={`playlist-${id}`}>
        <NavLink to={`/playlists/${id}`}>
          <div className="card__title" style={{backgroundImage: `url(${photo})`}}>
            <div className="title__holder title__holder-playlist">
            </div>
          </div>
        </NavLink>
        <div className="card__body card__body-playlist">
          <div className="card__actions--bands card__actions--bands-playlists">
            <div className="actions__sound-playlist">
              <PlayButtonPresentation
                id={id}
                origin="playlist"
              />
            </div>
          </div>
          <h3 className="title__text title__text-playlist text-truncate">
            <NavLink to={`/playlists/${id}`}>{name}</NavLink>
          </h3>
          <time className="card__time card__time--total card__time-playlist" dateTime="PT3H14M">
            {playlist.sections.length} sons - {this.formatTimeToConvert(playlist)}
          </time>
          <div className="card__description sr-only">
              {description}
          </div>
          <div className="actions card__actions">
            <div className="actions__sound">
              <AddListButton id={id} origin="playlist" icon={true} />
              <svg onClick={() => this.setState({show_info: true})} className="ml-3" width="38" height="38" viewBox="0 0 106 106" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M53 106C82.2711 106 106 82.2711 106 53C106 23.7289 82.2711 0 53 0C23.7289 0 0 23.7289 0 53C0 82.2711 23.7289 106 53 106Z" fill="#F74E37"/>
                <path d="M46.0821 43.9272H58.9317L52.2911 72.6147H57.8028L56.5079 78.3257H44.2559L50.8966 49.6382H44.7208L46.0821 43.9272ZM52.4239 31.8081C52.4239 31.2104 52.5346 30.646 52.7559 30.1147C52.9994 29.5835 53.3204 29.1297 53.7188 28.7534C54.1173 28.355 54.5821 28.0451 55.1134 27.8237C55.6446 27.5802 56.2201 27.4585 56.8399 27.4585C57.4376 27.4585 58.002 27.5802 58.5333 27.8237C59.0645 28.0451 59.5183 28.355 59.8946 28.7534C60.2931 29.1297 60.603 29.5835 60.8243 30.1147C61.0678 30.646 61.1895 31.2104 61.1895 31.8081C61.1895 32.4058 61.0678 32.9813 60.8243 33.5347C60.603 34.0659 60.2931 34.5418 59.8946 34.9624C59.5183 35.3608 59.0645 35.6818 58.5333 35.9253C58.002 36.1688 57.4376 36.2905 56.8399 36.2905C56.2201 36.2905 55.6446 36.1688 55.1134 35.9253C54.5821 35.6818 54.1173 35.3608 53.7188 34.9624C53.3204 34.5418 52.9994 34.0659 52.7559 33.5347C52.5346 32.9813 52.4239 32.4058 52.4239 31.8081Z" fill="#F2F2F2"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
    } else {
      playlists =
        <div className="e-card card--playlist card--title-opaque" key={`playlist-` + id}>
          <div className="card__header d-flex justify-content-end">
            <svg onClick={() => this.setState({show_info: false})} version="1.1" id="Calque_1" x="0px" y="0px" width="48" height="48" viewBox="0 0 595.275 841.891" enableBackground="new 0 0 595.275 841.891">
              <polygon fill="#FFFFFF" points="519.771,500.915 365.422,346.536 519.771,192.169 452.017,124.408 297.656,278.769 143.295,124.408
              75.535,192.169 229.901,346.536 75.505,500.915 143.259,568.669 297.656,414.284 452.017,568.669 "/>
            </svg>
          </div>
          <div className="card__bottom-playlist" dangerouslySetInnerHTML={renderHTML(description)}></div>
        </div>
    }

    return (
      <>
        {playlists}
      </>
    )
  }
}
