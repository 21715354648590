import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


class VolumeButton extends Component {
  componentDidMount() {
    $('.controls__volume').slider({
      classes: {
        'ui-slider': 'player__volume',
        'ui-slider-handle': 'volume__thumb',
      },
      range: 'min',
      min: 0,
      max: 100,
      value: 75,
      change: (event, ui) => {
        const audio_html_dom = document.getElementById("audio-player");
        audio_html_dom.volume = ui.value/100;
      }
    });
  }

  changeSound() {
  }

  render() {
    return (
      <div className="d-none d-lg-flex ml-3 align-items-center">
        volume
        <div className="controls__volume d-flex align-items-center ml-2">
          <svg height="30" width="100">
            <path d="M 0,0 100,0 0,10 Z" />
            <path d="M 100,30 0,20 V 30 Z" />
          </svg>
        </div>
      </div>
    )
  }
}

function mapsStateToProps(state) {
  return {
    active_user: state.active_user,
    selected_section: state.selected_section,
    is_playing: state.is_playing
  };
}

export default connect(mapsStateToProps, null, null)(VolumeButton);
