import { INCREASE_ACTIVE_SECTIONS_PAGE_NR } from '../actions/types';

export default function(state=1, action){
  switch (action.type) {
    case INCREASE_ACTIVE_SECTIONS_PAGE_NR :
      if (action.error === true) {
        return state;
      } else {
        console.log(parseInt(action.payload) + 1);
        return parseInt(action.payload) + 1
      }
  }
  return state;
}
