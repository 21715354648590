import {IMPORT_HOME_DISCONNECTED_SERIES} from '../types';
import axios from 'axios';
import URL from '../../helpers/helper_url'

export default function importHomeDisconnectSeries() {
  console.log("Import home disconnected series")
  const url = URL + `/home_disconnected_series`;
  const request = axios.get(url);

  return {
    type: IMPORT_HOME_DISCONNECTED_SERIES,
    payload: request
  };
};
