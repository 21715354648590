import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PlayButton from '../containers/play_button';
import NextButton from '../containers/next_button';
import VolumeButton from '../containers/volume_button';
import VitesseButton from '../containers/vitesse_button';
import SkipButton from '../containers/skip_button';

class PlayerInfo extends Component {

  render() {
    var visible = this.props.show_info ? "player__infos row d-flex collapse order-3": "d-none";
    return (
      <div className="row align-items-center">
        <div className="player__title d-none d-sm-flex col-sm-6 col-md-4 col-12">
         {this.props.selected_section.section.title_website}
        </div>

        <div className="console__controls col-sm-6 col-md-8 col-12 d-flex align-items-center justify-content-sm-end justify-content-center">

          <SkipButton type="rewind" value={-5}/>

          <PlayButton size="big" origin="player" position="controls__btn"/>
          <NextButton/>

          <SkipButton type="forward" value={15}/>
          <SkipButton type="forward" value={30} hidemobile={true}/>

          <VitesseButton/>
          <VolumeButton/>

        </div>
      </div>
      // <div className="row align-items-center">
      //   <div className="player__title d-none d-sm-flex col mb-3">
      //    {this.props.selected_section.section.title_website}
      //   </div>

      //   <div className="console__controls col-auto mx-auto d-flex align-items-center justify-content-between">

      //     <SkipButton type="rewind" value={-5}/>

      //     <PlayButton size="big" origin="player" position="controls__btn"/>
      //     <NextButton/>

      //     <SkipButton type="forward" value={15}/>
      //     <SkipButton type="forward" value={30} hidemobile={true}/>

      //     <VitesseButton/>
      //     <VolumeButton/>

      //   </div>
      // </div>
    );
  }
}

function mapsStateToProps(state) {
  return {
    selected_section: state.selected_section,
    show_info: state.show_info
  };
}

export default connect(mapsStateToProps, null, null)(PlayerInfo);
